import type { StringifiableRecord } from "query-string"
import { parseUrl, stringifyUrl } from "query-string"

export const buildURL = (url: string, params: StringifiableRecord): string => {
  const query = /\.gif$/.test(parseUrl(url).url)
    ? { ...params, auto: undefined }
    : params

  return stringifyUrl({ url, query })
}
