import { useState } from "react"
import { MoreVertical } from "react-feather"
import { useTranslation } from "react-i18next"
import clsx from "clsx"

import { SquareButton } from "../SquareButton"

import { SubscribeLinks } from "./SubscribeLinks"

export const ExtrasMenu: React.FC = () => {
  const { t } = useTranslation("podcasts")
  const [open, setOpen] = useState(false)

  return (
    <div className="relative">
      <SquareButton onClick={() => setOpen((x) => !x)}>
        <span className="sr-only">
          {t(open ? "extrasMenu.showMenu" : "extrasMenu.hideMenu")}
        </span>
        <MoreVertical />
      </SquareButton>
      <div
        className={clsx(
          "absolute right-0 bottom-0",
          "mb-20 p-4",
          "bg-white shadow-md dark:bg-primary-800",
          { "h-0 w-0 opacity-0": !open },
        )}
      >
        <SubscribeLinks isOpen={open} />
      </div>
    </div>
  )
}
