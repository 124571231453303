import type React from "react"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import { useWebMonetization } from "@interruptor/react-monetization"

import { AnalyticsEvent, trackEvent } from "~/utils/analytics/analytics.client"
import { CookieName, useCookie } from "~/utils/cookies"
import { daysToSeconds } from "~/utils/dates/daysToSeconds"

const cookieName = CookieName.SEEN_MONETIZATION_THANK_YOU

export const WebMonetizationToaster: React.FC = () => {
  const { t } = useTranslation("monetization")
  const { isActive } = useWebMonetization()
  const message = t("thanks")
  const [hasSeenThankYouMessage, setCookie] = useCookie(cookieName)

  useEffect(() => {
    if (!isActive || hasSeenThankYouMessage) return

    toast.success(message)
    setCookie(true, { maxAge: daysToSeconds(14) })
  }, [isActive, hasSeenThankYouMessage, message, setCookie])

  useEffect(() => {
    if (!isActive) return

    trackEvent(AnalyticsEvent.WEB_MONETIZATION_ACTIVE)
  }, [isActive])

  return null
}
