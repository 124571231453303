export enum DocumentType {
  ABOUT_PAGE = "about_page",
  ARTICLE = "article",
  AUTHOR = "author",
  CATEGORY = "category",
  COLOR_SCHEME = "color_scheme",
  CONTENT_THEME = "content_theme",
  CONTRIBUTE = "contribute",
  DATA_PAGE = "data_page",
  HOME_PAGE = "home_page",
  NEWSLETTER = "newsletter",
  NEWSLETTER_SERIES = "newsletter_series",
  PODCAST = "podcast",
  PODCAST_EPISODE = "podcast_episode",
  PRIVACY_POLICY = "privacy_policy",
}
