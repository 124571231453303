import { CookiesProvider } from "react-cookie"
import { I18nextProvider } from "react-i18next"
import { ParallaxProvider as ReactScrollParallaxProvider } from "react-scroll-parallax"
import type { AppContext, AppInitialProps, AppProps } from "next/app"
import App from "next/app"
import Head from "next/head"
import { useRouter } from "next/router"
import { WebMonetizationProvider } from "@interruptor/react-monetization"
import { Provider as JotaiProvider } from "jotai"

import type {
  SiteConfig as PrismicSiteConfig,
  SiteConfigQuery,
} from "~/api/prismic/graphql"
import {
  sendPrismicGraphQLRequest,
  SiteConfigDocument,
} from "~/api/prismic/graphql"
import LoadingBar from "~/components/LoadingBar"
import { PodcastPlayer } from "~/components/PodcastPlayer"
import { DefaultSEO } from "~/components/SEO/DefaultSEO"
import { ToastProvider } from "~/components/ToastProvider"
import { WebMonetizationToaster } from "~/components/WebMonetization"
import i18n from "~/i18n"
import { getLayoutComponent } from "~/layouts"
import { ReactQueryProvider } from "~/state/ReactQuery"
import type { SiteConfig, SiteConfigLink } from "~/state/SiteConfig"
import { SiteConfigContext } from "~/state/SiteConfig"
import type { FCWithChildren } from "~/types/react"
import { IS_ANALYTICS_ENABLED, IS_DEV_ENV } from "~/utils/config"
import { getListQueryNodes } from "~/utils/graphql"
import { withoutFalsyValues } from "~/utils/withoutFalsyValues"

import "tailwindcss/tailwind.css"
import "tippy.js/dist/tippy.css"
import "tippy.js/themes/material.css"
import "~/style/libs/react-toastify.scss"
import "~/style/main.scss"
// Default fonts
import "@fontsource/lora/variable.css"
import "@fontsource/lora/variable-italic.css"
import "@fontsource/manrope/variable.css"
// Extra font options
import "@fontsource/bitter/variable.css"
import "@fontsource/inter/variable.css"
import "@fontsource/proza-libre/latin.css"

if (
  process.env.NODE_ENV === "development" &&
  process.env.NEXT_PUBLIC_ENABLE_MSW
) {
  require("../api/mocks")
}

type SiteConfigProps = { siteConfig: SiteConfig | undefined }

type CustomApp = React.FC<AppProps & SiteConfigProps> & {
  getInitialProps: (
    context: AppContext,
  ) => Promise<AppInitialProps & SiteConfigProps>
}

const ParallaxProvider = ReactScrollParallaxProvider as FCWithChildren

const MyApp: CustomApp = ({ Component, pageProps, siteConfig }) => {
  const router = useRouter()
  const Layout = getLayoutComponent(router)

  return (
    <JotaiProvider>
      <WebMonetizationProvider>
        <CookiesProvider>
          <ReactQueryProvider>
            <I18nextProvider i18n={i18n}>
              <SiteConfigContext.Provider value={siteConfig}>
                <ParallaxProvider>
                  <DefaultSEO />
                  <Head>
                    {!IS_DEV_ENV && IS_ANALYTICS_ENABLED && (
                      <script
                        src="/js/script.js"
                        data-domain="interruptor.pt"
                        defer
                      />
                    )}
                  </Head>
                  <LoadingBar />
                  <Layout>
                    <Component {...pageProps} />
                  </Layout>
                  <PodcastPlayer />
                </ParallaxProvider>
                <ToastProvider />
                <WebMonetizationToaster />
              </SiteConfigContext.Provider>
            </I18nextProvider>
          </ReactQueryProvider>
        </CookiesProvider>
      </WebMonetizationProvider>
    </JotaiProvider>
  )
}

MyApp.getInitialProps = async (context) => {
  console.info("[App] Running get initial props")
  const [initialProps, siteConfigQuery] = await Promise.all([
    App.getInitialProps(context),
    sendPrismicGraphQLRequest<SiteConfigQuery>({ query: SiteConfigDocument }),
  ])

  const config = getListQueryNodes(siteConfigQuery["allSite_configs"]).find(
    Boolean,
  )

  if (!config) {
    return { ...initialProps, siteConfig: undefined }
  }

  const getLinkOrNothing = (
    link: NonNullable<
      | PrismicSiteConfig["footer_important_links"]
      | PrismicSiteConfig["navbar_links"]
    >[number],
  ): SiteConfigLink | null => {
    return link.text && link.url
      ? {
          text: link.text,
          url: link.url,
          isHighlighted: !!link.is_highlighted,
        }
      : null
  }

  const footerLinks = config.footer_important_links?.map(getLinkOrNothing) || []
  const navbarLinks = config.navbar_links?.map(getLinkOrNothing) || []

  return {
    ...initialProps,
    siteConfig: {
      footer: {
        importantLinks: withoutFalsyValues(footerLinks),
      },
      navbar: {
        links: withoutFalsyValues(navbarLinks),
      },
    },
  }
}

export default MyApp
