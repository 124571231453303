import { Mail, Rss } from "react-feather"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import clsx from "clsx"
import copy from "copy-to-clipboard"

import EDJNLogo from "~/assets/logos/EDJN_extended_logo.png"
import LogoWhite from "~/assets/logos/logo-white.svg"
import Link from "~/components/Link"
import { PageRoutes } from "~/enums/PageRoutes"
import { useCurrentEpisodeUid } from "~/state/Podcast"
import { getArticlesRSSFeedURL } from "~/utils/articles/getArticlesRSSFeedURL"
import { publicConfig } from "~/utils/config"
import { useSocialLinks } from "~/utils/hooks/useSocialLinks"

const {
  licence,
  links: { edjn },
} = publicConfig

export const Footer: React.FC = () => {
  const { t } = useTranslation(["footer", "social"])
  const [episodeUid] = useCurrentEpisodeUid()

  const socialLinks = useSocialLinks()

  const pageLinks = [
    {
      text: t("footer:links.about"),
      path: PageRoutes.ABOUT,
    },
    {
      text: t("footer:links.privacyPolicy"),
      path: PageRoutes.PRIVACY_POLICY,
    },
    {
      text: t("footer:links.data"),
      path: PageRoutes.DATA,
    },
    {
      text: t("footer:links.techBlog"),
      path: publicConfig.techBlogURL,
    },
    {
      text: t("footer:links.contribute"),
      path: PageRoutes.CONTRIBUTE,
      linkClass: "text-secondary-500",
    },
  ]

  return (
    <footer
      className={clsx("bg-black font-display text-white", {
        "pb-16 md:pb-20": !!episodeUid,
      })}
    >
      <div className="mx-auto flex w-full max-w-screen-lg flex-col flex-wrap py-12 md:flex-row">
        <div className="order-1 flex grow flex-col items-center p-4 md:order-2 md:items-start">
          <Link href="/">
            <img
              src={LogoWhite.src}
              alt={t("footer:logo.alt")}
              className="mb-4 w-48 md:mb-1"
            />
          </Link>
          <ul className="space-y-2 md:space-y-1">
            {pageLinks.map(({ text, path, linkClass = "text-white" }) => (
              <li
                key={path}
                className="list-none text-center font-medium md:text-left"
              >
                <Link href={path} className={linkClass}>
                  {text}
                </Link>
              </li>
            ))}
          </ul>
        </div>
        <div
          className={clsx(
            "flex flex-col justify-between",
            "order-2 md:order-3",
            "grow-0",
          )}
        >
          <div
            className={clsx(
              "flex items-start justify-center md:justify-end",
              "space-x-4 p-4",
            )}
          >
            <button
              onClick={() => {
                copy(getArticlesRSSFeedURL(), { format: "text/plain" })
                toast(t("general:copiedToClipboard.url"))
              }}
              title={t("footer:articlesRSS")}
              type="button"
            >
              <Rss strokeWidth="0.1rem" size={24} />
              <span className="sr-only">{t("footer:articlesRSS")}</span>
            </button>

            <a
              href={`mailto:${publicConfig.email.general}`}
              title={t("footer:email.title")}
            >
              <Mail
                aria-label={t("footer:email.title")}
                strokeWidth="0.1rem"
                size={24}
              />
            </a>
            {socialLinks.map(({ href, text, Icon }) => {
              return (
                <Link key={href} href={href} title={text} isExternal>
                  <Icon aria-label={text} strokeWidth="0.1rem" size={24} />
                  <span className="sr-only">{text}</span>
                </Link>
              )
            })}
          </div>
          <div className="flex flex-col items-center p-4 md:items-end">
            <small className="flex flex-col">
              <span className="mx-auto mb-1">{t("edjn.body")}</span>
              <Link
                className="rounded-[10px] bg-white p-1 px-2"
                href={edjn.url}
                isExternal
              >
                <img
                  src={EDJNLogo.src}
                  alt={edjn.name}
                  height={60}
                  width={155}
                  loading="lazy"
                />
              </Link>
            </small>
          </div>
        </div>
        <div className="order-3 p-4 md:order-1">
          <Link
            aria-label={licence.title}
            href={licence.url}
            className="flex flex-row justify-center"
            title={licence.title}
            isExternal
          >
            {licence.iconPaths.map((path) => (
              <img key={path} src={path} alt="" className="mx-px h-6 w-6" />
            ))}
            <span className="sr-only">{licence.title}</span>
          </Link>
        </div>
      </div>
    </footer>
  )
}
