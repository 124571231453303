import { useCallback, useEffect, useRef, useState } from "react"
import { animated, useSpring } from "react-spring"

import LazyImage from "~/components/LazyImage"
import { useIsPodcastPlaying } from "~/state/Podcast"
import { pickByTypename } from "~/utils/graphql"
import { getImageDetails } from "~/utils/prismic/assertJsonTypes"
import { formatTime, isAtLeastOneHour } from "~/utils/time"

import { ExtrasMenu } from "./ExtrasMenu"
import { PlayButton } from "./PlayButton"
import { ProgressBar } from "./ProgressBar"
import { useCurrentEpisodeQuery } from "./queries"
import { useIsPodcastBuffering } from "./state"

export const PodcastPlayer: React.FC = () => {
  const [, setIsBuffering] = useIsPodcastBuffering()
  const [isPlaying, setIsPlaying] = useIsPodcastPlaying()

  const { data, isLoading } = useCurrentEpisodeQuery()

  const episode = data?.episode
  const episodeURL = episode?.audio_file_url || undefined
  const podcast = pickByTypename(episode?.podcast, "Podcast")

  const [showPlayer, setShowPlayer] = useState(!!episodeURL)
  const spring = useSpring({
    bottom: showPlayer ? 0 : -100,
    opacity: showPlayer ? 1 : 0,
  })

  const audioRef = useRef<HTMLAudioElement>(null)
  const [audioState, setAudioState] = useState({
    currentTime: 0,
    duration: 0,
  })

  const triggerPlay = useCallback(async () => {
    const audio = audioRef.current

    if (!audio) return
    try {
      await audio.play()
    } catch (e) {
      audio.pause()
    }
  }, [])

  useEffect(() => {
    const player = audioRef.current

    if (!player) return

    if (isPlaying) {
      void triggerPlay()
    } else {
      player.pause()
    }
  }, [isPlaying, triggerPlay])

  useEffect(() => {
    if (episodeURL) void triggerPlay()
  }, [episodeURL, triggerPlay])

  useEffect(() => {
    if (isLoading && !showPlayer) setShowPlayer(true)
  }, [isLoading, showPlayer])

  return (
    <animated.section
      className="fixed z-10 w-full bg-white dark:bg-primary-800"
      style={spring}
    >
      <ProgressBar
        currentTime={audioState.currentTime}
        duration={audioState.duration}
        player={audioRef.current}
      />
      <div className="flex items-stretch space-x-2 md:space-x-4">
        <div className="h-16 w-16 shrink-0 self-center md:h-20 md:w-20">
          <LazyImage
            className="h-full w-full object-cover"
            src={getImageDetails(episode?.episode_image || podcast?.image)?.url}
            alt=""
            reallyLazy
          />
        </div>
        <div className="flex grow flex-col justify-center p-1">
          <h1 className="text-xs sm:text-sm md:text-base">{episode?.title}</h1>
          <p className="hidden text-xs md:inline-block md:text-sm">
            {podcast?.name}
          </p>
        </div>

        <div className="hidden items-center justify-center md:flex">
          {!!audioState.duration && (
            <div className="space-x-1 font-display">
              <span className="font-semibold">
                {formatTime(
                  audioState.currentTime,
                  isAtLeastOneHour(audioState.duration),
                )}
              </span>
              <span>/</span>
              <span className="text-sm">{formatTime(audioState.duration)}</span>
            </div>
          )}
        </div>

        <div className="flex items-center justify-center pr-2">
          <PlayButton />
          <ExtrasMenu />
        </div>
      </div>

      <audio
        key={episodeURL}
        src={episodeURL || undefined}
        ref={audioRef}
        onPlaying={() => setIsPlaying(true)}
        onCanPlay={() => setIsBuffering(false)}
        onWaiting={() => setIsBuffering(true)}
        onTimeUpdate={(e) => {
          const { currentTime = 0, duration = 0 } = e.currentTarget

          setAudioState({ currentTime, duration })
        }}
        onAbort={() => {
          setIsBuffering(false)
          setIsPlaying(false)
        }}
      />
    </animated.section>
  )
}
