import { useCallback, useEffect } from "react"

import { IS_DEV_ENV } from "~/utils/config"
import { useCookie } from "~/utils/cookies"
import { CookieName } from "~/utils/cookies/CookieName"
import { daysToSeconds } from "~/utils/dates/daysToSeconds"

export enum Theme {
  LIGHT = "light",
  DARK = "dark",
  PEACH = "peach",
  BLACK_AND_WHITE = "light__black-and-white",
  BLACK_AND_WHITE_DARK = "dark__black-and-white",
}

const ALL_THEMES = Object.values(Theme)
export const DEFAULT_THEMES = [Theme.DARK, Theme.LIGHT] as const
const ENABLED_THEMES = [
  ...DEFAULT_THEMES,
  Theme.PEACH,
  Theme.BLACK_AND_WHITE,
  Theme.BLACK_AND_WHITE_DARK,
] as const

// To make testing easier
export const getEnabledThemes = (): readonly Theme[] => {
  if (IS_DEV_ENV) return ALL_THEMES

  return ENABLED_THEMES
}

const ONE_YEAR = daysToSeconds(365)

export const useThemeCookie = (): [
  Theme | undefined,
  (theme: Theme) => void,
] => {
  const [themeCookie, setCookie] = useCookie<Theme>(CookieName.THEME)

  const setThemeCookie = useCallback(
    (value: Theme) => setCookie(value, { maxAge: ONE_YEAR }),
    [setCookie],
  )

  // If the user doesn't have a cookie, set the cookie to match their preference
  useEffect(() => {
    if (themeCookie) return
    const prefersDarkMode = window.matchMedia(
      "(prefers-color-scheme: dark)",
    ).matches

    setThemeCookie(prefersDarkMode ? Theme.DARK : Theme.LIGHT)
  }, [themeCookie, setThemeCookie])

  // If the user has the cookie, set the theme to match it
  useEffect(() => {
    if (!themeCookie) return

    const html = document.querySelector("html")

    const toggleClasses = (theme: Theme, isActive: boolean) => {
      const [htmlClass, bodyClass] = theme.split("__")
      html?.classList.toggle(htmlClass, isActive)
      if (bodyClass) document.body.classList.toggle(bodyClass, isActive)
    }

    ALL_THEMES.forEach((theme) => {
      toggleClasses(theme, false)
    })

    ALL_THEMES.some((theme) => {
      const isSelectedTheme = themeCookie === theme

      if (isSelectedTheme) {
        toggleClasses(theme, true)
      }

      return isSelectedTheme
    })
  }, [themeCookie])

  return [themeCookie, setThemeCookie]
}
