import { Rss } from "react-feather"
import { useTranslation } from "react-i18next"

import Link from "~/components/Link"
import {
  ApplePodcastsLogo,
  DeezerLogo,
  GooglePodcastsLogo,
  PocketCastsLogo,
  SpotifyLogo,
} from "~/components/Logos"
import { useCurrentEpisodeQuery } from "~/components/PodcastPlayer/queries"
import { pickByTypename } from "~/utils/graphql"
import { getPodcastRSSFeedURL } from "~/utils/podcasts/rss/getPodcastRSSFeedURL"

type SubscribeLink = {
  image: JSX.Element
  text: string
  url: string
}

export const SubscribeLinks: React.FC<{ isOpen: boolean }> = ({ isOpen }) => {
  const { t } = useTranslation("podcasts")
  const { data } = useCurrentEpisodeQuery()
  const podcast = pickByTypename(data?.episode?.podcast, "Podcast")

  if (!isOpen || !podcast) return null

  const {
    apple_url,
    deezer_url,
    google_podcasts_url,
    pocket_casts_url,
    spotify_url,
    _meta: { uid },
  } = podcast

  const links: SubscribeLink[] = [
    {
      text: t("subscribeLinks.rss"),
      url: uid && getPodcastRSSFeedURL({ uid }),
      image: <Rss strokeWidth={3} />,
    },
    {
      image: <SpotifyLogo />,
      text: t("subscribeLinks.spotify"),
      url: spotify_url,
    },
    {
      image: <ApplePodcastsLogo />,
      text: t("subscribeLinks.apple"),
      url: apple_url,
    },
    {
      image: <GooglePodcastsLogo />,
      text: t("subscribeLinks.google"),
      url: google_podcasts_url,
    },
    {
      image: <PocketCastsLogo />,
      text: t("subscribeLinks.pocketCasts"),
      url: pocket_casts_url,
    },
    {
      image: <DeezerLogo />,
      text: t("subscribeLinks.deezer"),
      url: deezer_url,
    },
  ].filter((x): x is SubscribeLink => !!x.url)

  return (
    <ul className="space-y-4 text-primary-700 dark:text-primary-100">
      {links.map(({ image, text, url }) => {
        return (
          <li key={url}>
            <Link href={url} title={text} isExternal>
              {image}
              <span className="sr-only">{text}</span>
            </Link>
          </li>
        )
      })}
    </ul>
  )
}
