import type { UseQueryOptions } from "react-query"
import { useQuery } from "react-query"
import { stringifyUrl } from "query-string"

import { fetchJSON } from "~/utils/fetch"
import { useDebouncedValue } from "~/utils/hooks/useDebouncedValue"

import type { SearchResponse, SearchResult } from "./types"

type SearchArgs = {
  searchText: string
}

type DefaultSearchResponse = SearchResponse["results"]

export const useSearchCMSQuery = <TData = SearchResult[]>(
  { searchText }: SearchArgs,
  options?: UseQueryOptions<SearchResult[], unknown, TData>,
) => {
  const q = useDebouncedValue(searchText.trim(), 200)

  return useQuery<DefaultSearchResponse, unknown, TData>(
    ["SearchCMS", q],
    async () => {
      const url = stringifyUrl({
        url: "/api/search",
        query: { q },
      })
      const res = await fetchJSON<SearchResponse>(url)

      return res.results
    },
    { ...options, enabled: !!q.length },
  )
}
