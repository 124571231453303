import React, { Fragment } from "react"
import { Popover, Transition } from "@headlessui/react"
import clsx from "clsx"

import type { FCWithChildren } from "~/types/react"

export type PopoverPanelProps = {
  className?: string
}

export const PopoverPanel: FCWithChildren<PopoverPanelProps> = ({
  className,
  children,
}) => {
  return (
    <Transition
      as={Fragment}
      enter="transition ease-out duration-200"
      enterFrom="opacity-0 translate-y-1"
      enterTo="opacity-100 translate-y-0"
      leave="transition ease-in duration-150"
      leaveFrom="opacity-100 translate-y-0"
      leaveTo="opacity-0 translate-y-1"
    >
      <Popover.Panel className={className}>
        <div
          className={clsx(
            "relative p-4",
            "bg-white dark:bg-primary-800",
            "rounded-lg shadow-lg ring-1 ring-black/5",
          )}
        >
          {children}
        </div>
      </Popover.Panel>
    </Transition>
  )
}
