import type { ComponentProps } from "react"
import clsx from "clsx"
import { Field } from "formik"

export type InputProps = {
  className?: string
  label: string
  placeholder: string
  isFormik?: boolean
  error?: React.ReactNode
} & ComponentProps<"input">

export function Input({
  className,
  label,
  isFormik,
  error,
  ...props
}: InputProps): JSX.Element {
  const InputElement = isFormik ? Field : "input"

  return (
    <label className="flex flex-col space-y-2 font-display">
      <span className="text-sm text-primary-700 theme-peach:text-primary-800 dark:text-secondary-500">
        {label}
      </span>

      <InputElement
        {...props}
        className={clsx(
          "max-w-md rounded p-2",
          "text-black dark:text-white",
          "placeholder:text-gray-700 dark:placeholder:text-primary-200",
          "bg-primary-50 dark:bg-primary-600",
          "border border-primary-200 dark:border-primary-400",
          className,
        )}
      />

      {error}
    </label>
  )
}
