import { paramCase } from "change-case"

import { ChunkyUnderline } from "~/components/ChunkyUnderline"
import LazyImage from "~/components/LazyImage"
import Link from "~/components/Link"

export type SearchResultProps = {
  title: string
  description: string
  href: string
  image: {
    src: string
    alt: string
  }
}

export const SearchResult = ({
  title,
  description,
  href,
  image,
}: SearchResultProps) => {
  const id = paramCase(href)

  return (
    <article>
      <Link
        href={href}
        title={title}
        aria-labelledby={id}
        className="flex h-24 space-x-3 md:space-x-4"
      >
        <LazyImage
          className="h-full w-24 shrink-0"
          src={image.src}
          alt={image.alt}
        />

        <div className="flex h-full flex-col overflow-hidden">
          <h1 id={id} className="mb-2 text-sm leading-tight md:text-base">
            <ChunkyUnderline>{title}</ChunkyUnderline>
          </h1>
          <p className="font-body text-xs font-normal line-clamp-2 md:line-clamp-3">
            {description}
          </p>
        </div>
      </Link>
    </article>
  )
}
