type PlausibleFn = (
  eventName: string,
  options?: { callback?: () => void },
) => void

declare global {
  interface Window {
    plausible?: PlausibleFn
  }
}

export enum AnalyticsEvent {
  ARTICLE_ADD_BOOKMARK = "Article: Add bookmark",
  CIRCUITOS_SIGN_UP = "Circuitos: sign-up",
  CONTRIBUTE_BUTTON_CLICK = "Contribute button click",
  HOMEPAGE_PLAY_PODCAST = "Homepage: Play podcast",
  PODCAST_DOWNLOAD_EPISODE = "Podcast: Download episode",
  WEB_MONETIZATION_ACTIVE = "Web Monetization active",
}

export const trackEvent: PlausibleFn = (event, options): void => {
  if (typeof window === "undefined") return
  const callback = options?.callback || (() => {})

  try {
    if (!window.plausible) {
      console.log(`[${event}] plausible isn't loaded, so just calling callback`)

      return callback()
    }

    window.plausible(event, options)
  } catch (e) {
    callback()
  }
}
