import React from "react"
import clsx from "clsx"

import type { ColorSchemeUIDs } from "~/enums/ColorSchemes"
import type { FCWithChildren } from "~/types/react"
import { getChunkyUnderlineClass } from "~/utils/style/getChunkyUnderlineClass"

type DisplayOptions = {
  inline: true
  inlineBlock: true
  inlineFlex: true
}

type OneDisplayOption<TKey extends keyof DisplayOptions> = Pick<
  DisplayOptions,
  TKey
> & {
  [K in keyof Omit<DisplayOptions, TKey>]?: undefined
}

type ChunkyUnderlineProps = {
  as?: React.ElementType
  className?: string
  color?: ColorSchemeUIDs
} & (
  | OneDisplayOption<"inline">
  | OneDisplayOption<"inlineBlock">
  | OneDisplayOption<"inlineFlex">
  | OneDisplayOption<never>
)

export const ChunkyUnderline: FCWithChildren<ChunkyUnderlineProps> = ({
  as: As = "span",
  children,
  className,
  color,
  inline,
  inlineBlock,
  inlineFlex,
}) => {
  return (
    <As
      className={clsx(
        inline && "inline",
        inlineBlock && "inline-block",
        inlineFlex && "inline-flex",
        className,
        getChunkyUnderlineClass(color),
      )}
    >
      {children}
    </As>
  )
}
