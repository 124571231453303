import * as Prismic from "@prismicio/client"
import type { Query } from "@prismicio/types"
import type { IncomingMessage } from "http"

import type {
  BasePrismicDocument,
  PrismicSearchResponse,
} from "~/api/prismic/types"
import { publicConfig } from "~/utils/config"

export type CreateClientOptions = {
  req?: IncomingMessage
  ref?: string
}

export const createPrismicClient = ({ req, ref }: CreateClientOptions = {}) => {
  const client = Prismic.createClient(publicConfig.prismic.repoName, {
    ref,
    defaultParams: {
      orderings: {
        field: "document.first_publication_date",
        direction: "desc",
      },
    },
  })

  if (req) {
    client.enableAutoPreviewsFromReq(req)
  }

  return client
}

export type QueryApiArgs = {
  req?: IncomingMessage
  ref?: string
} & Partial<Prismic.BuildQueryURLArgs>

export type QueryApi<
  T extends BasePrismicDocument,
  O extends Record<string, unknown> = Record<string, unknown>,
> = (options: QueryApiArgs & O) => Promise<Query<T>>

export async function queryApi<T extends BasePrismicDocument>({
  req,
  predicates = [],
  ref,
  ...options
}: QueryApiArgs): Promise<PrismicSearchResponse<T>> {
  const client = createPrismicClient({ req, ref })

  const searchResponse = await client.get<T>({
    ...options,
    predicates,
  })

  return searchResponse
}
