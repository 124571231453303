import { useEffect, useState } from "react"

export const useDebouncedValue = <TValue>(value: TValue, timeoutMS: number) => {
  const [savedValue, setSavedValue] = useState(value)

  useEffect(() => {
    const timeout = setTimeout(() => {
      setSavedValue(value)
    }, timeoutMS)

    return () => {
      clearTimeout(timeout)
    }
  }, [timeoutMS, value])

  return savedValue
}
