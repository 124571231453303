export const fetchJSON = async <TData>(
  ...args: Parameters<typeof fetch>
): Promise<TData> => {
  const res = await fetch(...args)

  if (!res.ok) {
    return Promise.reject(res.text())
  }

  return res.json()
}
