import type { CookieSetOptions } from "universal-cookie"

import { publicConfig } from "~/utils/config"
import { daysToSeconds } from "~/utils/dates/daysToSeconds"

const IS_PROD = process.env.NODE_ENV === "production"

const COOKIE_DOMAIN = publicConfig.siteURL
  .replace("https://", "")
  .replace("www.", ".")

export const defaultOptions: CookieSetOptions = {
  domain: IS_PROD ? COOKIE_DOMAIN : undefined,
  path: "/",
  sameSite: "strict",
  secure: IS_PROD,
  maxAge: daysToSeconds(30),
}
