import React from "react"
import { QueryClient, QueryClientProvider } from "react-query"
import { ReactQueryDevtools } from "react-query/devtools"
import type { Query } from "@prismicio/types"

import type { FCWithChildren } from "~/types/react"

export enum QueryCacheKeys {
  ALL_ARTICLES_AND_EPISODES = "ALL_ARTICLES_AND_EPISODES",
  DATASETS = "DATASETS",
  DOWNLOAD_FILE = "DOWNLOAD_FILE",
  RELATED_ARTICLES = "RELATED_ARTICLES",
  PODCAST = "PODCAST",
  PODCAST_EPISODE = "PODCAST_EPISODE",
  PODCAST_EPISODES_LIST = "PODCAST_EPISODES_LIST",
}

const client = new QueryClient({
  defaultOptions: {
    queries: {
      enabled: typeof window !== "undefined",
      getNextPageParam: (unknown) => {
        if (typeof unknown === "object" && unknown && "results" in unknown) {
          const lastPage = unknown as Query

          if (lastPage.total_pages > lastPage.page) {
            return lastPage.page + 1
          }
        }

        return undefined
      },
    },
  },
})

export const ReactQueryProvider: FCWithChildren = ({ children, ...props }) => {
  return (
    <QueryClientProvider client={client} {...props}>
      {children}
      <ReactQueryDevtools />
    </QueryClientProvider>
  )
}
