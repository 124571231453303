import Head from "next/head"
import { useRouter } from "next/router"

import type { MaybeImage } from "~/types/images"
import type { Maybe } from "~/types/ts-utils"
import { publicConfig } from "~/utils/config"
import { imageOrFallback } from "~/utils/images/imageOrFallback"

export type SEOProps = {
  pageTitle: string
  withTitleSuffix?: boolean
  description?: Maybe<string>
  image?: MaybeImage
  path?: string
  twitter?: {
    cardType: "summary" | "summary_large_image"
  }
  type?: "website" | "article"
  children?: React.ReactNode
}

type MetaProps = React.DetailedHTMLProps<
  React.MetaHTMLAttributes<HTMLMetaElement>,
  HTMLMetaElement
> &
  ({ name: string } | { property: string })

export const SEO: React.FC<SEOProps> = ({
  children,
  pageTitle,
  description: descriptionProp,
  image,
  path,
  twitter,
  type = "website",
  withTitleSuffix = true,
}) => {
  const router = useRouter()

  const pageURL = `${publicConfig.siteURL}${path || router.asPath}`
  const cardType = twitter ? twitter.cardType : "summary"
  const imageURL = imageOrFallback(image?.url)
  const description = descriptionProp || publicConfig.description

  const metadata: MetaProps[] = [
    { name: "description", content: description },
    { name: "twitter:card", content: cardType },
    {
      name: "twitter:site",
      content: publicConfig.socialMedia.twitter.username,
    },
    { name: "twitter:title", content: pageTitle },
    { name: "twitter:description", content: description },
    { name: "twitter:image", content: imageURL },
    { name: "twitter:image:alt", content: image?.alt || "" },
    { property: "og:url", content: pageURL },
    { property: "og:type", content: type },
    { property: "og:title", content: pageTitle },
    { property: "og:description", content: description },
    { property: "og:image", content: imageURL },
  ]

  const title = [pageTitle]

  if (withTitleSuffix) title.push(publicConfig.title)

  return (
    <Head>
      <title>{title.join(" | ")}</title>

      {metadata.map((metaProps) => (
        <meta {...metaProps} key={metaProps.name || metaProps.property} />
      ))}

      {children}
    </Head>
  )
}
