import Link from "~/components/Link"
import { useSocialLinks } from "~/utils/hooks/useSocialLinks"

export const SocialIcons: React.FC = () => {
  const socialLinks = useSocialLinks()

  return (
    <ul className="flex flex-row items-center space-x-4">
      {socialLinks.map(({ href, text, Icon }) => (
        <li key={href}>
          <Link href={href} isExternal>
            <Icon />
            <span className="sr-only">{text}</span>
          </Link>
        </li>
      ))}
    </ul>
  )
}
