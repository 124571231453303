import clsx from "clsx"

import { ColorSchemeUIDs } from "~/enums/ColorSchemes"

export function getChunkyUnderlineClass(color?: string | null): string {
  switch (color) {
    case ColorSchemeUIDs.FOREST_GREEN:
      return clsx(
        `chunky-underline-forestGreen-200 dark:chunky-underline-forestGreen-500`,
      )

    case ColorSchemeUIDs.BLUE:
      return clsx(`chunky-underline-blue-200 dark:chunky-underline-blue-500`)

    case ColorSchemeUIDs.PINK:
      return clsx(`chunky-underline-pink-200 dark:chunky-underline-pink-500`)

    case ColorSchemeUIDs.TEAL:
      return clsx(`chunky-underline-teal-200 dark:chunky-underline-teal-500`)

    case ColorSchemeUIDs.VIOLET:
    default:
      return clsx(
        "chunky-underline-primary-200",
        "dark:chunky-underline-primary-500",
      )
  }
}
