import clsx from "clsx"

import Link from "~/components/Link"
import { useSiteConfig } from "~/state/SiteConfig"
import { useHasHydrated } from "~/utils/hooks/useHasHydrated"

import { MainNavLogo } from "./MainNavLogo"
import { MobileMenu } from "./MobileMenu"
import { SearchMenu } from "./SearchMenu"
import { SettingsMenu } from "./SettingsMenu"

export const NavBar: React.FC = () => {
  const { navbar } = useSiteConfig()
  const hasHydrated = useHasHydrated()

  return (
    <header className="font-display font-semibold">
      <div
        id="nav"
        className="mx-auto flex max-w-screen-lg items-center justify-between p-3 md:p-4"
      >
        <Link href="/">
          <MainNavLogo />
        </Link>

        <nav
          className={clsx(
            "flex items-center justify-end",
            "text-primary-700 theme-peach:text-black dark:text-secondary-400 dark:theme-bw:text-white",
          )}
        >
          <ul className="m-0 hidden space-x-6 md:flex">
            {navbar.links.map(({ text, url, isHighlighted }) => {
              if (isHighlighted) {
                return (
                  <li key={url}>
                    <Link
                      href={url}
                      className={clsx(
                        "bg-primary-500 text-white hover:text-secondary-500",
                        "dark:bg-secondary-400 dark:text-primary-800",
                        "theme-peach:bg-secondary-400 theme-peach:text-black",
                        "theme-bw:bg-secondary-400 theme-bw:text-black",
                        "dark:hover:bg-secondary-200 dark:hover:text-primary-800",
                        "rounded py-2 px-4 duration-100",
                      )}
                    >
                      {text}
                    </Link>
                  </li>
                )
              }

              return (
                <li
                  key={url}
                  className="flex list-none items-center leading-none"
                >
                  <Link href={url} className="no-underline hover:underline">
                    {text}
                  </Link>
                </li>
              )
            })}
          </ul>

          <div className="ml-4 flex flex-col items-end">
            <SearchMenu />
          </div>

          <div className="ml-4 flex flex-col items-end">
            {hasHydrated && <SettingsMenu />}
          </div>

          <div className="ml-4 md:m-0">
            <MobileMenu />
          </div>
        </nav>
      </div>
    </header>
  )
}
