/* Automatically generated code. Do not edit this file directly */
/* eslint-disable */
import { useQuery, UseQueryOptions } from "react-query"
import { prismicGraphQLClient } from "~/api/prismic/graphql/client"
export type Maybe<T> = T | null | undefined
export type InputMaybe<T> = T | null | undefined
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  /** DateTime */
  DateTime: string
  /** Raw JSON value */
  Json: prismic.ImageDetails | prismic.RichTextField
  /** The `Long` scalar type represents non-fractional signed whole numeric values. Long can represent values between -(2^63) and 2^63 - 1. */
  Long: number
}

export type AboutPage = Document &
  Linkable & {
    _linkType?: Maybe<Scalars["String"]>
    _meta: Meta
    body?: Maybe<Array<AboutPageBody>>
    title?: Maybe<Scalars["Json"]>
  }

export type AboutPageBody = AboutPageBodySection

export type AboutPageBodySection = {
  label?: Maybe<Scalars["String"]>
  primary?: Maybe<AboutPageBodySectionPrimary>
  type?: Maybe<Scalars["String"]>
}

export type AboutPageBodySectionPrimary = {
  section_body?: Maybe<Scalars["Json"]>
  section_title?: Maybe<Scalars["Json"]>
}

/** A connection to a list of items. */
export type AboutPageConnectionConnection = {
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<AboutPageConnectionEdge>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  totalCount: Scalars["Long"]
}

/** An edge in a connection. */
export type AboutPageConnectionEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]
  /** The item at the end of the edge. */
  node: AboutPage
}

export type Article = Document &
  Linkable & {
    _linkType?: Maybe<Scalars["String"]>
    _meta: Meta
    audio_url?: Maybe<Scalars["String"]>
    author?: Maybe<Linkable>
    authors?: Maybe<Array<ArticleAuthors>>
    banner_image?: Maybe<Scalars["Json"]>
    banner_image_credits?: Maybe<Scalars["Json"]>
    body?: Maybe<Array<ArticleBody>>
    categories?: Maybe<Array<ArticleCategories>>
    color_scheme?: Maybe<Linkable>
    featured?: Maybe<Scalars["Boolean"]>
    footnotes?: Maybe<Array<ArticleFootnotes>>
    meta_description?: Maybe<Scalars["String"]>
    subtitle?: Maybe<Scalars["Json"]>
    title?: Maybe<Scalars["Json"]>
    zotero_footnotes_url?: Maybe<Scalars["String"]>
  }

export type ArticleAuthors = {
  link?: Maybe<Linkable>
}

export type ArticleBody =
  | ArticleBodyAlert
  | ArticleBodyChartEmbed
  | ArticleBodyFullWidthImage
  | ArticleBodyHighlightedQuote
  | ArticleBodyHtmlEmbed
  | ArticleBodyImageGallery
  | ArticleBodyMarkdown
  | ArticleBodyQuote
  | ArticleBodyTables
  | ArticleBodyText

export type ArticleBodyAlert = {
  label?: Maybe<Scalars["String"]>
  primary?: Maybe<ArticleBodyAlertPrimary>
  type?: Maybe<Scalars["String"]>
}

export type ArticleBodyAlertPrimary = {
  color?: Maybe<Scalars["String"]>
  text?: Maybe<Scalars["Json"]>
}

export type ArticleBodyChartEmbed = {
  label?: Maybe<Scalars["String"]>
  primary?: Maybe<ArticleBodyChartEmbedPrimary>
  type?: Maybe<Scalars["String"]>
}

export type ArticleBodyChartEmbedPrimary = {
  iframe_title?: Maybe<Scalars["String"]>
  iframe_url?: Maybe<Scalars["String"]>
}

export type ArticleBodyFullWidthImage = {
  label?: Maybe<Scalars["String"]>
  primary?: Maybe<ArticleBodyFullWidthImagePrimary>
  type?: Maybe<Scalars["String"]>
}

export type ArticleBodyFullWidthImagePrimary = {
  caption?: Maybe<Scalars["Json"]>
  credit?: Maybe<Scalars["Json"]>
  image?: Maybe<Scalars["Json"]>
  image_size?: Maybe<Scalars["String"]>
  with_parallax?: Maybe<Scalars["Boolean"]>
}

export type ArticleBodyHighlightedQuote = {
  label?: Maybe<Scalars["String"]>
  primary?: Maybe<ArticleBodyHighlightedQuotePrimary>
  type?: Maybe<Scalars["String"]>
}

export type ArticleBodyHighlightedQuotePrimary = {
  quote_text?: Maybe<Scalars["Json"]>
}

export type ArticleBodyHtmlEmbed = {
  label?: Maybe<Scalars["String"]>
  primary?: Maybe<ArticleBodyHtmlEmbedPrimary>
  type?: Maybe<Scalars["String"]>
}

export type ArticleBodyHtmlEmbedPrimary = {
  add_padding?: Maybe<Scalars["Boolean"]>
  background_color?: Maybe<Scalars["String"]>
  change_background_color?: Maybe<Scalars["Boolean"]>
  html?: Maybe<Scalars["Json"]>
  iframe_title?: Maybe<Scalars["String"]>
}

export type ArticleBodyImageGallery = {
  fields?: Maybe<Array<ArticleBodyImageGalleryFields>>
  label?: Maybe<Scalars["String"]>
  primary?: Maybe<ArticleBodyImageGalleryPrimary>
  type?: Maybe<Scalars["String"]>
}

export type ArticleBodyImageGalleryFields = {
  credit?: Maybe<Scalars["Json"]>
  gallery_image?: Maybe<Scalars["Json"]>
  image_captions?: Maybe<Scalars["Json"]>
}

export type ArticleBodyImageGalleryPrimary = {
  display_type?: Maybe<Scalars["String"]>
  gallery_name?: Maybe<Scalars["Json"]>
  max_tiles_per_row_desktop?: Maybe<Scalars["String"]>
  max_tiles_per_row_mobile?: Maybe<Scalars["String"]>
}

export type ArticleBodyMarkdown = {
  label?: Maybe<Scalars["String"]>
  primary?: Maybe<ArticleBodyMarkdownPrimary>
  type?: Maybe<Scalars["String"]>
}

export type ArticleBodyMarkdownPrimary = {
  markdown?: Maybe<Scalars["Json"]>
}

export type ArticleBodyQuote = {
  label?: Maybe<Scalars["String"]>
  primary?: Maybe<ArticleBodyQuotePrimary>
  type?: Maybe<Scalars["String"]>
}

export type ArticleBodyQuotePrimary = {
  author_name?: Maybe<Scalars["Json"]>
  author_portrait?: Maybe<Scalars["Json"]>
  quote?: Maybe<Scalars["Json"]>
}

export type ArticleBodyTables = {
  fields?: Maybe<Array<ArticleBodyTablesFields>>
  label?: Maybe<Scalars["String"]>
  primary?: Maybe<ArticleBodyTablesPrimary>
  type?: Maybe<Scalars["String"]>
}

export type ArticleBodyTablesFields = {
  csv?: Maybe<Linkable>
  has_header?: Maybe<Scalars["Boolean"]>
  table_description?: Maybe<Scalars["Json"]>
}

export type ArticleBodyTablesPrimary = {
  show_side_by_side?: Maybe<Scalars["Boolean"]>
}

export type ArticleBodyText = {
  label?: Maybe<Scalars["String"]>
  primary?: Maybe<ArticleBodyTextPrimary>
  type?: Maybe<Scalars["String"]>
}

export type ArticleBodyTextPrimary = {
  text?: Maybe<Scalars["Json"]>
  text_size?: Maybe<Scalars["String"]>
}

export type ArticleCategories = {
  category_link?: Maybe<Linkable>
}

/** A connection to a list of items. */
export type ArticleConnectionConnection = {
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ArticleConnectionEdge>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  totalCount: Scalars["Long"]
}

/** An edge in a connection. */
export type ArticleConnectionEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]
  /** The item at the end of the edge. */
  node: Article
}

export type ArticleFootnotes = {
  additional_info?: Maybe<Scalars["Json"]>
  link_to_ref?: Maybe<Linkable>
  number?: Maybe<Scalars["Float"]>
  ref_title?: Maybe<Scalars["String"]>
}

export type Author = Document &
  Linkable & {
    _linkType?: Maybe<Scalars["String"]>
    _meta: Meta
    bio?: Maybe<Scalars["Json"]>
    instagram?: Maybe<Scalars["String"]>
    name?: Maybe<Scalars["String"]>
    profile_picture?: Maybe<Scalars["Json"]>
    twitter_handle?: Maybe<Scalars["String"]>
  }

/** A connection to a list of items. */
export type AuthorConnectionConnection = {
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<AuthorConnectionEdge>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  totalCount: Scalars["Long"]
}

/** An edge in a connection. */
export type AuthorConnectionEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]
  /** The item at the end of the edge. */
  node: Author
}

export type Category = Document &
  Linkable & {
    _linkType?: Maybe<Scalars["String"]>
    _meta: Meta
    color_scheme?: Maybe<Linkable>
    name?: Maybe<Scalars["String"]>
  }

/** A connection to a list of items. */
export type CategoryConnectionConnection = {
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<CategoryConnectionEdge>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  totalCount: Scalars["Long"]
}

/** An edge in a connection. */
export type CategoryConnectionEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]
  /** The item at the end of the edge. */
  node: Category
}

export type ColorScheme = Document &
  Linkable & {
    _linkType?: Maybe<Scalars["String"]>
    _meta: Meta
    name?: Maybe<Scalars["String"]>
  }

/** A connection to a list of items. */
export type ColorSchemeConnectionConnection = {
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ColorSchemeConnectionEdge>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  totalCount: Scalars["Long"]
}

/** An edge in a connection. */
export type ColorSchemeConnectionEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]
  /** The item at the end of the edge. */
  node: ColorScheme
}

export type ContentTheme = Document &
  Linkable & {
    _linkType?: Maybe<Scalars["String"]>
    _meta: Meta
    authors?: Maybe<Array<ContentThemeAuthors>>
    banner_image?: Maybe<Scalars["Json"]>
    heading?: Maybe<Scalars["Json"]>
    name?: Maybe<Scalars["String"]>
  }

export type ContentThemeAuthors = {
  author?: Maybe<Linkable>
  connection?: Maybe<Linkable>
}

/** A connection to a list of items. */
export type ContentThemeConnectionConnection = {
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ContentThemeConnectionEdge>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  totalCount: Scalars["Long"]
}

/** An edge in a connection. */
export type ContentThemeConnectionEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]
  /** The item at the end of the edge. */
  node: ContentTheme
}

export type Contribute = Document &
  Linkable & {
    _linkType?: Maybe<Scalars["String"]>
    _meta: Meta
    body?: Maybe<Array<ContributeBody>>
    title?: Maybe<Scalars["Json"]>
  }

export type ContributeBody =
  | ContributeBodyOpenCollective
  | ContributeBodySection

export type ContributeBodyOpenCollective = {
  label?: Maybe<Scalars["String"]>
  primary?: Maybe<ContributeBodyOpenCollectivePrimary>
  type?: Maybe<Scalars["String"]>
}

export type ContributeBodyOpenCollectivePrimary = {
  url?: Maybe<Scalars["String"]>
}

export type ContributeBodySection = {
  label?: Maybe<Scalars["String"]>
  primary?: Maybe<ContributeBodySectionPrimary>
  type?: Maybe<Scalars["String"]>
}

export type ContributeBodySectionPrimary = {
  section_body?: Maybe<Scalars["Json"]>
  section_title?: Maybe<Scalars["Json"]>
}

/** A connection to a list of items. */
export type ContributeConnectionConnection = {
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ContributeConnectionEdge>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  totalCount: Scalars["Long"]
}

/** An edge in a connection. */
export type ContributeConnectionEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]
  /** The item at the end of the edge. */
  node: Contribute
}

export type DataPage = Document &
  Linkable & {
    _linkType?: Maybe<Scalars["String"]>
    _meta: Meta
    description?: Maybe<Scalars["Json"]>
    seo_description?: Maybe<Scalars["String"]>
    seo_image?: Maybe<Scalars["Json"]>
    title?: Maybe<Scalars["Json"]>
  }

/** A connection to a list of items. */
export type DataPageConnectionConnection = {
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<DataPageConnectionEdge>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  totalCount: Scalars["Long"]
}

/** An edge in a connection. */
export type DataPageConnectionEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]
  /** The item at the end of the edge. */
  node: DataPage
}

export type Homepage = Document &
  Linkable & {
    _linkType?: Maybe<Scalars["String"]>
    _meta: Meta
    body?: Maybe<Array<HomepageBody>>
  }

export type HomepageBody =
  | HomepageBodyHighlights
  | HomepageBodyNewestPodcastEpisode
  | HomepageBodySeries
  | HomepageBodySingleFeaturedArticle

export type HomepageBodyHighlights = {
  fields?: Maybe<Array<HomepageBodyHighlightsFields>>
  label?: Maybe<Scalars["String"]>
  type?: Maybe<Scalars["String"]>
}

export type HomepageBodyHighlightsFields = {
  highlight?: Maybe<Linkable>
}

export type HomepageBodyNewestPodcastEpisode = {
  label?: Maybe<Scalars["String"]>
  type?: Maybe<Scalars["String"]>
}

export type HomepageBodySeries = {
  fields?: Maybe<Array<HomepageBodySeriesFields>>
  label?: Maybe<Scalars["String"]>
  type?: Maybe<Scalars["String"]>
}

export type HomepageBodySeriesFields = {
  category?: Maybe<Linkable>
}

export type HomepageBodySingleFeaturedArticle = {
  label?: Maybe<Scalars["String"]>
  primary?: Maybe<HomepageBodySingleFeaturedArticlePrimary>
  type?: Maybe<Scalars["String"]>
}

export type HomepageBodySingleFeaturedArticlePrimary = {
  article?: Maybe<Linkable>
}

/** A connection to a list of items. */
export type HomepageConnectionConnection = {
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<HomepageConnectionEdge>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  totalCount: Scalars["Long"]
}

/** An edge in a connection. */
export type HomepageConnectionEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]
  /** The item at the end of the edge. */
  node: Homepage
}

export type Meta = {
  /** Alternate languages the document. */
  alternateLanguages: Array<RelatedDocument>
  /** The first publication date of the document. */
  firstPublicationDate?: Maybe<Scalars["DateTime"]>
  /** The id of the document. */
  id: Scalars["String"]
  /** The language of the document. */
  lang: Scalars["String"]
  /** The last publication date of the document. */
  lastPublicationDate?: Maybe<Scalars["DateTime"]>
  /** The tags of the document. */
  tags: Array<Scalars["String"]>
  /** The type of the document. */
  type: Scalars["String"]
  /** The uid of the document. */
  uid?: Maybe<Scalars["String"]>
}

export type Newsletter = Document &
  Linkable & {
    _linkType?: Maybe<Scalars["String"]>
    _meta: Meta
    description?: Maybe<Scalars["String"]>
    preview_image?: Maybe<Scalars["Json"]>
    recommendations?: Maybe<Array<NewsletterRecommendations>>
    series?: Maybe<Linkable>
    title?: Maybe<Scalars["Json"]>
  }

/** A connection to a list of items. */
export type NewsletterConnectionConnection = {
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<NewsletterConnectionEdge>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  totalCount: Scalars["Long"]
}

/** An edge in a connection. */
export type NewsletterConnectionEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]
  /** The item at the end of the edge. */
  node: Newsletter
}

export type NewsletterRecommendations = {
  image?: Maybe<Scalars["Json"]>
  text?: Maybe<Scalars["Json"]>
}

export type NewsletterSeries = Document &
  Linkable & {
    _linkType?: Maybe<Scalars["String"]>
    _meta: Meta
    image?: Maybe<Scalars["Json"]>
    meta_description?: Maybe<Scalars["String"]>
    meta_title?: Maybe<Scalars["String"]>
    name?: Maybe<Scalars["String"]>
    page_content?: Maybe<Scalars["Json"]>
  }

/** A connection to a list of items. */
export type NewsletterSeriesConnectionConnection = {
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<NewsletterSeriesConnectionEdge>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  totalCount: Scalars["Long"]
}

/** An edge in a connection. */
export type NewsletterSeriesConnectionEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]
  /** The item at the end of the edge. */
  node: NewsletterSeries
}

/** Information about pagination in a connection. */
export type PageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars["String"]>
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars["Boolean"]
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars["Boolean"]
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars["String"]>
}

export type Podcast = Document &
  Linkable & {
    _linkType?: Maybe<Scalars["String"]>
    _meta: Meta
    apple_url?: Maybe<Scalars["String"]>
    banner_image?: Maybe<Scalars["Json"]>
    categories?: Maybe<Array<PodcastCategories>>
    color_scheme?: Maybe<Linkable>
    copyright?: Maybe<Scalars["String"]>
    deezer_url?: Maybe<Scalars["String"]>
    description?: Maybe<Scalars["String"]>
    google_podcasts_url?: Maybe<Scalars["String"]>
    image?: Maybe<Scalars["Json"]>
    language?: Maybe<Scalars["String"]>
    max_episodes?: Maybe<Scalars["Float"]>
    name?: Maybe<Scalars["String"]>
    owner_email?: Maybe<Scalars["String"]>
    owner_name?: Maybe<Scalars["String"]>
    pocket_casts_url?: Maybe<Scalars["String"]>
    show_link?: Maybe<Scalars["String"]>
    spotify_url?: Maybe<Scalars["String"]>
    subtitle?: Maybe<Scalars["String"]>
  }

export type PodcastCategories = {
  category_name?: Maybe<Scalars["String"]>
}

/** A connection to a list of items. */
export type PodcastConnectionConnection = {
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<PodcastConnectionEdge>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  totalCount: Scalars["Long"]
}

/** An edge in a connection. */
export type PodcastConnectionEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]
  /** The item at the end of the edge. */
  node: Podcast
}

export type PodcastEpisode = Document &
  Linkable & {
    _linkType?: Maybe<Scalars["String"]>
    _meta: Meta
    audio_file_url?: Maybe<Scalars["String"]>
    categories?: Maybe<Array<PodcastEpisodeCategories>>
    episode_image?: Maybe<Scalars["Json"]>
    image?: Maybe<Scalars["Json"]>
    itunes_episode_type?: Maybe<Scalars["String"]>
    podcast?: Maybe<Linkable>
    short_description?: Maybe<Scalars["String"]>
    show_notes?: Maybe<Scalars["Json"]>
    title?: Maybe<Scalars["String"]>
    transcription?: Maybe<Scalars["Json"]>
  }

export type PodcastEpisodeCategories = {
  category_link?: Maybe<Linkable>
}

/** A connection to a list of items. */
export type PodcastEpisodeConnectionConnection = {
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<PodcastEpisodeConnectionEdge>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  totalCount: Scalars["Long"]
}

/** An edge in a connection. */
export type PodcastEpisodeConnectionEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]
  /** The item at the end of the edge. */
  node: PodcastEpisode
}

export type PrivacyPolicy = Document &
  Linkable & {
    _linkType?: Maybe<Scalars["String"]>
    _meta: Meta
    body?: Maybe<Array<PrivacyPolicyBody>>
    title?: Maybe<Scalars["Json"]>
  }

export type PrivacyPolicyBody =
  | PrivacyPolicyBodyCookiesInfo
  | PrivacyPolicyBodySection

export type PrivacyPolicyBodyCookiesInfo = {
  label?: Maybe<Scalars["String"]>
  primary?: Maybe<PrivacyPolicyBodyCookiesInfoPrimary>
  type?: Maybe<Scalars["String"]>
}

export type PrivacyPolicyBodyCookiesInfoPrimary = {
  cookie?: Maybe<Scalars["String"]>
  cookie_title?: Maybe<Scalars["Json"]>
  description?: Maybe<Scalars["Json"]>
  show_value_in_table?: Maybe<Scalars["Boolean"]>
}

export type PrivacyPolicyBodySection = {
  label?: Maybe<Scalars["String"]>
  primary?: Maybe<PrivacyPolicyBodySectionPrimary>
  type?: Maybe<Scalars["String"]>
}

export type PrivacyPolicyBodySectionPrimary = {
  section_body?: Maybe<Scalars["Json"]>
  section_title?: Maybe<Scalars["Json"]>
}

/** A connection to a list of items. */
export type PrivacyPolicyConnectionConnection = {
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<PrivacyPolicyConnectionEdge>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  totalCount: Scalars["Long"]
}

/** An edge in a connection. */
export type PrivacyPolicyConnectionEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]
  /** The item at the end of the edge. */
  node: PrivacyPolicy
}

export type Query = {
  _allDocuments: DocumentConnection
  allAbout_pages: AboutPageConnectionConnection
  allArticles: ArticleConnectionConnection
  allAuthors: AuthorConnectionConnection
  allCategorys: CategoryConnectionConnection
  allColor_schemes: ColorSchemeConnectionConnection
  allContent_themes: ContentThemeConnectionConnection
  allContributes: ContributeConnectionConnection
  allData_pages: DataPageConnectionConnection
  allHomepages: HomepageConnectionConnection
  allNewsletter_seriess: NewsletterSeriesConnectionConnection
  allNewsletters: NewsletterConnectionConnection
  allPodcast_episodes: PodcastEpisodeConnectionConnection
  allPodcasts: PodcastConnectionConnection
  allPrivacy_policys: PrivacyPolicyConnectionConnection
  allSite_configs: SiteConfigConnectionConnection
  article?: Maybe<Article>
  author?: Maybe<Author>
  category?: Maybe<Category>
  color_scheme?: Maybe<ColorScheme>
  content_theme?: Maybe<ContentTheme>
  homepage?: Maybe<Homepage>
  newsletter?: Maybe<Newsletter>
  newsletter_series?: Maybe<NewsletterSeries>
  podcast?: Maybe<Podcast>
  podcast_episode?: Maybe<PodcastEpisode>
}

export type QueryAllDocumentsArgs = {
  after?: InputMaybe<Scalars["String"]>
  before?: InputMaybe<Scalars["String"]>
  first?: InputMaybe<Scalars["Int"]>
  firstPublicationDate?: InputMaybe<Scalars["DateTime"]>
  firstPublicationDate_after?: InputMaybe<Scalars["DateTime"]>
  firstPublicationDate_before?: InputMaybe<Scalars["DateTime"]>
  fulltext?: InputMaybe<Scalars["String"]>
  id?: InputMaybe<Scalars["String"]>
  id_in?: InputMaybe<Array<Scalars["String"]>>
  lang?: InputMaybe<Scalars["String"]>
  last?: InputMaybe<Scalars["Int"]>
  lastPublicationDate?: InputMaybe<Scalars["DateTime"]>
  lastPublicationDate_after?: InputMaybe<Scalars["DateTime"]>
  lastPublicationDate_before?: InputMaybe<Scalars["DateTime"]>
  similar?: InputMaybe<Similar>
  sortBy?: InputMaybe<SortDocumentsBy>
  tags?: InputMaybe<Array<Scalars["String"]>>
  tags_in?: InputMaybe<Array<Scalars["String"]>>
  type?: InputMaybe<Scalars["String"]>
  type_in?: InputMaybe<Array<Scalars["String"]>>
}

export type QueryAllAboutPagesArgs = {
  after?: InputMaybe<Scalars["String"]>
  before?: InputMaybe<Scalars["String"]>
  first?: InputMaybe<Scalars["Int"]>
  firstPublicationDate?: InputMaybe<Scalars["DateTime"]>
  firstPublicationDate_after?: InputMaybe<Scalars["DateTime"]>
  firstPublicationDate_before?: InputMaybe<Scalars["DateTime"]>
  fulltext?: InputMaybe<Scalars["String"]>
  id?: InputMaybe<Scalars["String"]>
  id_in?: InputMaybe<Array<Scalars["String"]>>
  lang?: InputMaybe<Scalars["String"]>
  last?: InputMaybe<Scalars["Int"]>
  lastPublicationDate?: InputMaybe<Scalars["DateTime"]>
  lastPublicationDate_after?: InputMaybe<Scalars["DateTime"]>
  lastPublicationDate_before?: InputMaybe<Scalars["DateTime"]>
  similar?: InputMaybe<Similar>
  sortBy?: InputMaybe<SortAboutPagey>
  tags?: InputMaybe<Array<Scalars["String"]>>
  tags_in?: InputMaybe<Array<Scalars["String"]>>
  uid?: InputMaybe<Scalars["String"]>
  uid_in?: InputMaybe<Array<Scalars["String"]>>
  where?: InputMaybe<WhereAboutPage>
}

export type QueryAllArticlesArgs = {
  after?: InputMaybe<Scalars["String"]>
  before?: InputMaybe<Scalars["String"]>
  first?: InputMaybe<Scalars["Int"]>
  firstPublicationDate?: InputMaybe<Scalars["DateTime"]>
  firstPublicationDate_after?: InputMaybe<Scalars["DateTime"]>
  firstPublicationDate_before?: InputMaybe<Scalars["DateTime"]>
  fulltext?: InputMaybe<Scalars["String"]>
  id?: InputMaybe<Scalars["String"]>
  id_in?: InputMaybe<Array<Scalars["String"]>>
  lang?: InputMaybe<Scalars["String"]>
  last?: InputMaybe<Scalars["Int"]>
  lastPublicationDate?: InputMaybe<Scalars["DateTime"]>
  lastPublicationDate_after?: InputMaybe<Scalars["DateTime"]>
  lastPublicationDate_before?: InputMaybe<Scalars["DateTime"]>
  similar?: InputMaybe<Similar>
  sortBy?: InputMaybe<SortArticley>
  tags?: InputMaybe<Array<Scalars["String"]>>
  tags_in?: InputMaybe<Array<Scalars["String"]>>
  uid?: InputMaybe<Scalars["String"]>
  uid_in?: InputMaybe<Array<Scalars["String"]>>
  where?: InputMaybe<WhereArticle>
}

export type QueryAllAuthorsArgs = {
  after?: InputMaybe<Scalars["String"]>
  before?: InputMaybe<Scalars["String"]>
  first?: InputMaybe<Scalars["Int"]>
  firstPublicationDate?: InputMaybe<Scalars["DateTime"]>
  firstPublicationDate_after?: InputMaybe<Scalars["DateTime"]>
  firstPublicationDate_before?: InputMaybe<Scalars["DateTime"]>
  fulltext?: InputMaybe<Scalars["String"]>
  id?: InputMaybe<Scalars["String"]>
  id_in?: InputMaybe<Array<Scalars["String"]>>
  lang?: InputMaybe<Scalars["String"]>
  last?: InputMaybe<Scalars["Int"]>
  lastPublicationDate?: InputMaybe<Scalars["DateTime"]>
  lastPublicationDate_after?: InputMaybe<Scalars["DateTime"]>
  lastPublicationDate_before?: InputMaybe<Scalars["DateTime"]>
  similar?: InputMaybe<Similar>
  sortBy?: InputMaybe<SortAuthory>
  tags?: InputMaybe<Array<Scalars["String"]>>
  tags_in?: InputMaybe<Array<Scalars["String"]>>
  uid?: InputMaybe<Scalars["String"]>
  uid_in?: InputMaybe<Array<Scalars["String"]>>
  where?: InputMaybe<WhereAuthor>
}

export type QueryAllCategorysArgs = {
  after?: InputMaybe<Scalars["String"]>
  before?: InputMaybe<Scalars["String"]>
  first?: InputMaybe<Scalars["Int"]>
  firstPublicationDate?: InputMaybe<Scalars["DateTime"]>
  firstPublicationDate_after?: InputMaybe<Scalars["DateTime"]>
  firstPublicationDate_before?: InputMaybe<Scalars["DateTime"]>
  fulltext?: InputMaybe<Scalars["String"]>
  id?: InputMaybe<Scalars["String"]>
  id_in?: InputMaybe<Array<Scalars["String"]>>
  lang?: InputMaybe<Scalars["String"]>
  last?: InputMaybe<Scalars["Int"]>
  lastPublicationDate?: InputMaybe<Scalars["DateTime"]>
  lastPublicationDate_after?: InputMaybe<Scalars["DateTime"]>
  lastPublicationDate_before?: InputMaybe<Scalars["DateTime"]>
  similar?: InputMaybe<Similar>
  sortBy?: InputMaybe<SortCategoryy>
  tags?: InputMaybe<Array<Scalars["String"]>>
  tags_in?: InputMaybe<Array<Scalars["String"]>>
  uid?: InputMaybe<Scalars["String"]>
  uid_in?: InputMaybe<Array<Scalars["String"]>>
  where?: InputMaybe<WhereCategory>
}

export type QueryAllColorSchemesArgs = {
  after?: InputMaybe<Scalars["String"]>
  before?: InputMaybe<Scalars["String"]>
  first?: InputMaybe<Scalars["Int"]>
  firstPublicationDate?: InputMaybe<Scalars["DateTime"]>
  firstPublicationDate_after?: InputMaybe<Scalars["DateTime"]>
  firstPublicationDate_before?: InputMaybe<Scalars["DateTime"]>
  fulltext?: InputMaybe<Scalars["String"]>
  id?: InputMaybe<Scalars["String"]>
  id_in?: InputMaybe<Array<Scalars["String"]>>
  lang?: InputMaybe<Scalars["String"]>
  last?: InputMaybe<Scalars["Int"]>
  lastPublicationDate?: InputMaybe<Scalars["DateTime"]>
  lastPublicationDate_after?: InputMaybe<Scalars["DateTime"]>
  lastPublicationDate_before?: InputMaybe<Scalars["DateTime"]>
  similar?: InputMaybe<Similar>
  sortBy?: InputMaybe<SortColorSchemey>
  tags?: InputMaybe<Array<Scalars["String"]>>
  tags_in?: InputMaybe<Array<Scalars["String"]>>
  uid?: InputMaybe<Scalars["String"]>
  uid_in?: InputMaybe<Array<Scalars["String"]>>
  where?: InputMaybe<WhereColorScheme>
}

export type QueryAllContentThemesArgs = {
  after?: InputMaybe<Scalars["String"]>
  before?: InputMaybe<Scalars["String"]>
  first?: InputMaybe<Scalars["Int"]>
  firstPublicationDate?: InputMaybe<Scalars["DateTime"]>
  firstPublicationDate_after?: InputMaybe<Scalars["DateTime"]>
  firstPublicationDate_before?: InputMaybe<Scalars["DateTime"]>
  fulltext?: InputMaybe<Scalars["String"]>
  id?: InputMaybe<Scalars["String"]>
  id_in?: InputMaybe<Array<Scalars["String"]>>
  lang?: InputMaybe<Scalars["String"]>
  last?: InputMaybe<Scalars["Int"]>
  lastPublicationDate?: InputMaybe<Scalars["DateTime"]>
  lastPublicationDate_after?: InputMaybe<Scalars["DateTime"]>
  lastPublicationDate_before?: InputMaybe<Scalars["DateTime"]>
  similar?: InputMaybe<Similar>
  sortBy?: InputMaybe<SortContentThemey>
  tags?: InputMaybe<Array<Scalars["String"]>>
  tags_in?: InputMaybe<Array<Scalars["String"]>>
  uid?: InputMaybe<Scalars["String"]>
  uid_in?: InputMaybe<Array<Scalars["String"]>>
  where?: InputMaybe<WhereContentTheme>
}

export type QueryAllContributesArgs = {
  after?: InputMaybe<Scalars["String"]>
  before?: InputMaybe<Scalars["String"]>
  first?: InputMaybe<Scalars["Int"]>
  firstPublicationDate?: InputMaybe<Scalars["DateTime"]>
  firstPublicationDate_after?: InputMaybe<Scalars["DateTime"]>
  firstPublicationDate_before?: InputMaybe<Scalars["DateTime"]>
  fulltext?: InputMaybe<Scalars["String"]>
  id?: InputMaybe<Scalars["String"]>
  id_in?: InputMaybe<Array<Scalars["String"]>>
  lang?: InputMaybe<Scalars["String"]>
  last?: InputMaybe<Scalars["Int"]>
  lastPublicationDate?: InputMaybe<Scalars["DateTime"]>
  lastPublicationDate_after?: InputMaybe<Scalars["DateTime"]>
  lastPublicationDate_before?: InputMaybe<Scalars["DateTime"]>
  similar?: InputMaybe<Similar>
  sortBy?: InputMaybe<SortContributey>
  tags?: InputMaybe<Array<Scalars["String"]>>
  tags_in?: InputMaybe<Array<Scalars["String"]>>
  uid?: InputMaybe<Scalars["String"]>
  uid_in?: InputMaybe<Array<Scalars["String"]>>
  where?: InputMaybe<WhereContribute>
}

export type QueryAllDataPagesArgs = {
  after?: InputMaybe<Scalars["String"]>
  before?: InputMaybe<Scalars["String"]>
  first?: InputMaybe<Scalars["Int"]>
  firstPublicationDate?: InputMaybe<Scalars["DateTime"]>
  firstPublicationDate_after?: InputMaybe<Scalars["DateTime"]>
  firstPublicationDate_before?: InputMaybe<Scalars["DateTime"]>
  fulltext?: InputMaybe<Scalars["String"]>
  id?: InputMaybe<Scalars["String"]>
  id_in?: InputMaybe<Array<Scalars["String"]>>
  lang?: InputMaybe<Scalars["String"]>
  last?: InputMaybe<Scalars["Int"]>
  lastPublicationDate?: InputMaybe<Scalars["DateTime"]>
  lastPublicationDate_after?: InputMaybe<Scalars["DateTime"]>
  lastPublicationDate_before?: InputMaybe<Scalars["DateTime"]>
  similar?: InputMaybe<Similar>
  sortBy?: InputMaybe<SortDataPagey>
  tags?: InputMaybe<Array<Scalars["String"]>>
  tags_in?: InputMaybe<Array<Scalars["String"]>>
  uid?: InputMaybe<Scalars["String"]>
  uid_in?: InputMaybe<Array<Scalars["String"]>>
  where?: InputMaybe<WhereDataPage>
}

export type QueryAllHomepagesArgs = {
  after?: InputMaybe<Scalars["String"]>
  before?: InputMaybe<Scalars["String"]>
  first?: InputMaybe<Scalars["Int"]>
  firstPublicationDate?: InputMaybe<Scalars["DateTime"]>
  firstPublicationDate_after?: InputMaybe<Scalars["DateTime"]>
  firstPublicationDate_before?: InputMaybe<Scalars["DateTime"]>
  fulltext?: InputMaybe<Scalars["String"]>
  id?: InputMaybe<Scalars["String"]>
  id_in?: InputMaybe<Array<Scalars["String"]>>
  lang?: InputMaybe<Scalars["String"]>
  last?: InputMaybe<Scalars["Int"]>
  lastPublicationDate?: InputMaybe<Scalars["DateTime"]>
  lastPublicationDate_after?: InputMaybe<Scalars["DateTime"]>
  lastPublicationDate_before?: InputMaybe<Scalars["DateTime"]>
  similar?: InputMaybe<Similar>
  sortBy?: InputMaybe<SortHomepagey>
  tags?: InputMaybe<Array<Scalars["String"]>>
  tags_in?: InputMaybe<Array<Scalars["String"]>>
  uid?: InputMaybe<Scalars["String"]>
  uid_in?: InputMaybe<Array<Scalars["String"]>>
}

export type QueryAllNewsletterSeriessArgs = {
  after?: InputMaybe<Scalars["String"]>
  before?: InputMaybe<Scalars["String"]>
  first?: InputMaybe<Scalars["Int"]>
  firstPublicationDate?: InputMaybe<Scalars["DateTime"]>
  firstPublicationDate_after?: InputMaybe<Scalars["DateTime"]>
  firstPublicationDate_before?: InputMaybe<Scalars["DateTime"]>
  fulltext?: InputMaybe<Scalars["String"]>
  id?: InputMaybe<Scalars["String"]>
  id_in?: InputMaybe<Array<Scalars["String"]>>
  lang?: InputMaybe<Scalars["String"]>
  last?: InputMaybe<Scalars["Int"]>
  lastPublicationDate?: InputMaybe<Scalars["DateTime"]>
  lastPublicationDate_after?: InputMaybe<Scalars["DateTime"]>
  lastPublicationDate_before?: InputMaybe<Scalars["DateTime"]>
  similar?: InputMaybe<Similar>
  sortBy?: InputMaybe<SortNewsletterSeriesy>
  tags?: InputMaybe<Array<Scalars["String"]>>
  tags_in?: InputMaybe<Array<Scalars["String"]>>
  uid?: InputMaybe<Scalars["String"]>
  uid_in?: InputMaybe<Array<Scalars["String"]>>
  where?: InputMaybe<WhereNewsletterSeries>
}

export type QueryAllNewslettersArgs = {
  after?: InputMaybe<Scalars["String"]>
  before?: InputMaybe<Scalars["String"]>
  first?: InputMaybe<Scalars["Int"]>
  firstPublicationDate?: InputMaybe<Scalars["DateTime"]>
  firstPublicationDate_after?: InputMaybe<Scalars["DateTime"]>
  firstPublicationDate_before?: InputMaybe<Scalars["DateTime"]>
  fulltext?: InputMaybe<Scalars["String"]>
  id?: InputMaybe<Scalars["String"]>
  id_in?: InputMaybe<Array<Scalars["String"]>>
  lang?: InputMaybe<Scalars["String"]>
  last?: InputMaybe<Scalars["Int"]>
  lastPublicationDate?: InputMaybe<Scalars["DateTime"]>
  lastPublicationDate_after?: InputMaybe<Scalars["DateTime"]>
  lastPublicationDate_before?: InputMaybe<Scalars["DateTime"]>
  similar?: InputMaybe<Similar>
  sortBy?: InputMaybe<SortNewslettery>
  tags?: InputMaybe<Array<Scalars["String"]>>
  tags_in?: InputMaybe<Array<Scalars["String"]>>
  uid?: InputMaybe<Scalars["String"]>
  uid_in?: InputMaybe<Array<Scalars["String"]>>
  where?: InputMaybe<WhereNewsletter>
}

export type QueryAllPodcastEpisodesArgs = {
  after?: InputMaybe<Scalars["String"]>
  before?: InputMaybe<Scalars["String"]>
  first?: InputMaybe<Scalars["Int"]>
  firstPublicationDate?: InputMaybe<Scalars["DateTime"]>
  firstPublicationDate_after?: InputMaybe<Scalars["DateTime"]>
  firstPublicationDate_before?: InputMaybe<Scalars["DateTime"]>
  fulltext?: InputMaybe<Scalars["String"]>
  id?: InputMaybe<Scalars["String"]>
  id_in?: InputMaybe<Array<Scalars["String"]>>
  lang?: InputMaybe<Scalars["String"]>
  last?: InputMaybe<Scalars["Int"]>
  lastPublicationDate?: InputMaybe<Scalars["DateTime"]>
  lastPublicationDate_after?: InputMaybe<Scalars["DateTime"]>
  lastPublicationDate_before?: InputMaybe<Scalars["DateTime"]>
  similar?: InputMaybe<Similar>
  sortBy?: InputMaybe<SortPodcastEpisodey>
  tags?: InputMaybe<Array<Scalars["String"]>>
  tags_in?: InputMaybe<Array<Scalars["String"]>>
  uid?: InputMaybe<Scalars["String"]>
  uid_in?: InputMaybe<Array<Scalars["String"]>>
  where?: InputMaybe<WherePodcastEpisode>
}

export type QueryAllPodcastsArgs = {
  after?: InputMaybe<Scalars["String"]>
  before?: InputMaybe<Scalars["String"]>
  first?: InputMaybe<Scalars["Int"]>
  firstPublicationDate?: InputMaybe<Scalars["DateTime"]>
  firstPublicationDate_after?: InputMaybe<Scalars["DateTime"]>
  firstPublicationDate_before?: InputMaybe<Scalars["DateTime"]>
  fulltext?: InputMaybe<Scalars["String"]>
  id?: InputMaybe<Scalars["String"]>
  id_in?: InputMaybe<Array<Scalars["String"]>>
  lang?: InputMaybe<Scalars["String"]>
  last?: InputMaybe<Scalars["Int"]>
  lastPublicationDate?: InputMaybe<Scalars["DateTime"]>
  lastPublicationDate_after?: InputMaybe<Scalars["DateTime"]>
  lastPublicationDate_before?: InputMaybe<Scalars["DateTime"]>
  similar?: InputMaybe<Similar>
  sortBy?: InputMaybe<SortPodcasty>
  tags?: InputMaybe<Array<Scalars["String"]>>
  tags_in?: InputMaybe<Array<Scalars["String"]>>
  uid?: InputMaybe<Scalars["String"]>
  uid_in?: InputMaybe<Array<Scalars["String"]>>
  where?: InputMaybe<WherePodcast>
}

export type QueryAllPrivacyPolicysArgs = {
  after?: InputMaybe<Scalars["String"]>
  before?: InputMaybe<Scalars["String"]>
  first?: InputMaybe<Scalars["Int"]>
  firstPublicationDate?: InputMaybe<Scalars["DateTime"]>
  firstPublicationDate_after?: InputMaybe<Scalars["DateTime"]>
  firstPublicationDate_before?: InputMaybe<Scalars["DateTime"]>
  fulltext?: InputMaybe<Scalars["String"]>
  id?: InputMaybe<Scalars["String"]>
  id_in?: InputMaybe<Array<Scalars["String"]>>
  lang?: InputMaybe<Scalars["String"]>
  last?: InputMaybe<Scalars["Int"]>
  lastPublicationDate?: InputMaybe<Scalars["DateTime"]>
  lastPublicationDate_after?: InputMaybe<Scalars["DateTime"]>
  lastPublicationDate_before?: InputMaybe<Scalars["DateTime"]>
  similar?: InputMaybe<Similar>
  sortBy?: InputMaybe<SortPrivacyPolicyy>
  tags?: InputMaybe<Array<Scalars["String"]>>
  tags_in?: InputMaybe<Array<Scalars["String"]>>
  uid?: InputMaybe<Scalars["String"]>
  uid_in?: InputMaybe<Array<Scalars["String"]>>
  where?: InputMaybe<WherePrivacyPolicy>
}

export type QueryAllSiteConfigsArgs = {
  after?: InputMaybe<Scalars["String"]>
  before?: InputMaybe<Scalars["String"]>
  first?: InputMaybe<Scalars["Int"]>
  firstPublicationDate?: InputMaybe<Scalars["DateTime"]>
  firstPublicationDate_after?: InputMaybe<Scalars["DateTime"]>
  firstPublicationDate_before?: InputMaybe<Scalars["DateTime"]>
  fulltext?: InputMaybe<Scalars["String"]>
  id?: InputMaybe<Scalars["String"]>
  id_in?: InputMaybe<Array<Scalars["String"]>>
  lang?: InputMaybe<Scalars["String"]>
  last?: InputMaybe<Scalars["Int"]>
  lastPublicationDate?: InputMaybe<Scalars["DateTime"]>
  lastPublicationDate_after?: InputMaybe<Scalars["DateTime"]>
  lastPublicationDate_before?: InputMaybe<Scalars["DateTime"]>
  similar?: InputMaybe<Similar>
  sortBy?: InputMaybe<SortSiteConfigy>
  tags?: InputMaybe<Array<Scalars["String"]>>
  tags_in?: InputMaybe<Array<Scalars["String"]>>
  uid?: InputMaybe<Scalars["String"]>
  uid_in?: InputMaybe<Array<Scalars["String"]>>
  where?: InputMaybe<WhereSiteConfig>
}

export type QueryArticleArgs = {
  lang: Scalars["String"]
  uid: Scalars["String"]
}

export type QueryAuthorArgs = {
  lang: Scalars["String"]
  uid: Scalars["String"]
}

export type QueryCategoryArgs = {
  lang: Scalars["String"]
  uid: Scalars["String"]
}

export type QueryColorSchemeArgs = {
  lang: Scalars["String"]
  uid: Scalars["String"]
}

export type QueryContentThemeArgs = {
  lang: Scalars["String"]
  uid: Scalars["String"]
}

export type QueryHomepageArgs = {
  lang: Scalars["String"]
  uid: Scalars["String"]
}

export type QueryNewsletterArgs = {
  lang: Scalars["String"]
  uid: Scalars["String"]
}

export type QueryNewsletterSeriesArgs = {
  lang: Scalars["String"]
  uid: Scalars["String"]
}

export type QueryPodcastArgs = {
  lang: Scalars["String"]
  uid: Scalars["String"]
}

export type QueryPodcastEpisodeArgs = {
  lang: Scalars["String"]
  uid: Scalars["String"]
}

export type RelatedDocument = {
  /** The id of the document. */
  id: Scalars["String"]
  /** The language of the document. */
  lang: Scalars["String"]
  /** The type of the document. */
  type: Scalars["String"]
  /** The uid of the document. */
  uid?: Maybe<Scalars["String"]>
}

export type SiteConfig = Document &
  Linkable & {
    _linkType?: Maybe<Scalars["String"]>
    _meta: Meta
    footer_important_links?: Maybe<Array<SiteConfigFooterImportantLinks>>
    navbar_links?: Maybe<Array<SiteConfigNavbarLinks>>
  }

/** A connection to a list of items. */
export type SiteConfigConnectionConnection = {
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<SiteConfigConnectionEdge>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  totalCount: Scalars["Long"]
}

/** An edge in a connection. */
export type SiteConfigConnectionEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]
  /** The item at the end of the edge. */
  node: SiteConfig
}

export type SiteConfigFooterImportantLinks = {
  is_highlighted?: Maybe<Scalars["Boolean"]>
  text?: Maybe<Scalars["String"]>
  url?: Maybe<Scalars["String"]>
}

export type SiteConfigNavbarLinks = {
  is_highlighted?: Maybe<Scalars["Boolean"]>
  text?: Maybe<Scalars["String"]>
  url?: Maybe<Scalars["String"]>
}

export enum SortAboutPagey {
  META_FIRSTPUBLICATIONDATE_ASC = "meta_firstPublicationDate_ASC",
  META_FIRSTPUBLICATIONDATE_DESC = "meta_firstPublicationDate_DESC",
  META_LASTPUBLICATIONDATE_ASC = "meta_lastPublicationDate_ASC",
  META_LASTPUBLICATIONDATE_DESC = "meta_lastPublicationDate_DESC",
  TITLE_ASC = "title_ASC",
  TITLE_DESC = "title_DESC",
}

export enum SortArticley {
  AUDIO_URL_ASC = "audio_url_ASC",
  AUDIO_URL_DESC = "audio_url_DESC",
  BANNER_IMAGE_CREDITS_ASC = "banner_image_credits_ASC",
  BANNER_IMAGE_CREDITS_DESC = "banner_image_credits_DESC",
  META_DESCRIPTION_ASC = "meta_description_ASC",
  META_DESCRIPTION_DESC = "meta_description_DESC",
  META_FIRSTPUBLICATIONDATE_ASC = "meta_firstPublicationDate_ASC",
  META_FIRSTPUBLICATIONDATE_DESC = "meta_firstPublicationDate_DESC",
  META_LASTPUBLICATIONDATE_ASC = "meta_lastPublicationDate_ASC",
  META_LASTPUBLICATIONDATE_DESC = "meta_lastPublicationDate_DESC",
  SUBTITLE_ASC = "subtitle_ASC",
  SUBTITLE_DESC = "subtitle_DESC",
  TITLE_ASC = "title_ASC",
  TITLE_DESC = "title_DESC",
  ZOTERO_FOOTNOTES_URL_ASC = "zotero_footnotes_url_ASC",
  ZOTERO_FOOTNOTES_URL_DESC = "zotero_footnotes_url_DESC",
}

export enum SortAuthory {
  BIO_ASC = "bio_ASC",
  BIO_DESC = "bio_DESC",
  INSTAGRAM_ASC = "instagram_ASC",
  INSTAGRAM_DESC = "instagram_DESC",
  META_FIRSTPUBLICATIONDATE_ASC = "meta_firstPublicationDate_ASC",
  META_FIRSTPUBLICATIONDATE_DESC = "meta_firstPublicationDate_DESC",
  META_LASTPUBLICATIONDATE_ASC = "meta_lastPublicationDate_ASC",
  META_LASTPUBLICATIONDATE_DESC = "meta_lastPublicationDate_DESC",
  NAME_ASC = "name_ASC",
  NAME_DESC = "name_DESC",
  TWITTER_HANDLE_ASC = "twitter_handle_ASC",
  TWITTER_HANDLE_DESC = "twitter_handle_DESC",
}

export enum SortCategoryy {
  META_FIRSTPUBLICATIONDATE_ASC = "meta_firstPublicationDate_ASC",
  META_FIRSTPUBLICATIONDATE_DESC = "meta_firstPublicationDate_DESC",
  META_LASTPUBLICATIONDATE_ASC = "meta_lastPublicationDate_ASC",
  META_LASTPUBLICATIONDATE_DESC = "meta_lastPublicationDate_DESC",
  NAME_ASC = "name_ASC",
  NAME_DESC = "name_DESC",
}

export enum SortColorSchemey {
  META_FIRSTPUBLICATIONDATE_ASC = "meta_firstPublicationDate_ASC",
  META_FIRSTPUBLICATIONDATE_DESC = "meta_firstPublicationDate_DESC",
  META_LASTPUBLICATIONDATE_ASC = "meta_lastPublicationDate_ASC",
  META_LASTPUBLICATIONDATE_DESC = "meta_lastPublicationDate_DESC",
  NAME_ASC = "name_ASC",
  NAME_DESC = "name_DESC",
}

export enum SortContentThemey {
  HEADING_ASC = "heading_ASC",
  HEADING_DESC = "heading_DESC",
  META_FIRSTPUBLICATIONDATE_ASC = "meta_firstPublicationDate_ASC",
  META_FIRSTPUBLICATIONDATE_DESC = "meta_firstPublicationDate_DESC",
  META_LASTPUBLICATIONDATE_ASC = "meta_lastPublicationDate_ASC",
  META_LASTPUBLICATIONDATE_DESC = "meta_lastPublicationDate_DESC",
  NAME_ASC = "name_ASC",
  NAME_DESC = "name_DESC",
}

export enum SortContributey {
  META_FIRSTPUBLICATIONDATE_ASC = "meta_firstPublicationDate_ASC",
  META_FIRSTPUBLICATIONDATE_DESC = "meta_firstPublicationDate_DESC",
  META_LASTPUBLICATIONDATE_ASC = "meta_lastPublicationDate_ASC",
  META_LASTPUBLICATIONDATE_DESC = "meta_lastPublicationDate_DESC",
  TITLE_ASC = "title_ASC",
  TITLE_DESC = "title_DESC",
}

export enum SortDataPagey {
  DESCRIPTION_ASC = "description_ASC",
  DESCRIPTION_DESC = "description_DESC",
  META_FIRSTPUBLICATIONDATE_ASC = "meta_firstPublicationDate_ASC",
  META_FIRSTPUBLICATIONDATE_DESC = "meta_firstPublicationDate_DESC",
  META_LASTPUBLICATIONDATE_ASC = "meta_lastPublicationDate_ASC",
  META_LASTPUBLICATIONDATE_DESC = "meta_lastPublicationDate_DESC",
  SEO_DESCRIPTION_ASC = "seo_description_ASC",
  SEO_DESCRIPTION_DESC = "seo_description_DESC",
  TITLE_ASC = "title_ASC",
  TITLE_DESC = "title_DESC",
}

export enum SortDocumentsBy {
  META_FIRSTPUBLICATIONDATE_ASC = "meta_firstPublicationDate_ASC",
  META_FIRSTPUBLICATIONDATE_DESC = "meta_firstPublicationDate_DESC",
  META_LASTPUBLICATIONDATE_ASC = "meta_lastPublicationDate_ASC",
  META_LASTPUBLICATIONDATE_DESC = "meta_lastPublicationDate_DESC",
}

export enum SortHomepagey {
  META_FIRSTPUBLICATIONDATE_ASC = "meta_firstPublicationDate_ASC",
  META_FIRSTPUBLICATIONDATE_DESC = "meta_firstPublicationDate_DESC",
  META_LASTPUBLICATIONDATE_ASC = "meta_lastPublicationDate_ASC",
  META_LASTPUBLICATIONDATE_DESC = "meta_lastPublicationDate_DESC",
}

export enum SortNewsletterSeriesy {
  META_DESCRIPTION_ASC = "meta_description_ASC",
  META_DESCRIPTION_DESC = "meta_description_DESC",
  META_FIRSTPUBLICATIONDATE_ASC = "meta_firstPublicationDate_ASC",
  META_FIRSTPUBLICATIONDATE_DESC = "meta_firstPublicationDate_DESC",
  META_LASTPUBLICATIONDATE_ASC = "meta_lastPublicationDate_ASC",
  META_LASTPUBLICATIONDATE_DESC = "meta_lastPublicationDate_DESC",
  META_TITLE_ASC = "meta_title_ASC",
  META_TITLE_DESC = "meta_title_DESC",
  NAME_ASC = "name_ASC",
  NAME_DESC = "name_DESC",
  PAGE_CONTENT_ASC = "page_content_ASC",
  PAGE_CONTENT_DESC = "page_content_DESC",
}

export enum SortNewslettery {
  DESCRIPTION_ASC = "description_ASC",
  DESCRIPTION_DESC = "description_DESC",
  META_FIRSTPUBLICATIONDATE_ASC = "meta_firstPublicationDate_ASC",
  META_FIRSTPUBLICATIONDATE_DESC = "meta_firstPublicationDate_DESC",
  META_LASTPUBLICATIONDATE_ASC = "meta_lastPublicationDate_ASC",
  META_LASTPUBLICATIONDATE_DESC = "meta_lastPublicationDate_DESC",
  TITLE_ASC = "title_ASC",
  TITLE_DESC = "title_DESC",
}

export enum SortPodcastEpisodey {
  AUDIO_FILE_URL_ASC = "audio_file_url_ASC",
  AUDIO_FILE_URL_DESC = "audio_file_url_DESC",
  ITUNES_EPISODE_TYPE_ASC = "itunes_episode_type_ASC",
  ITUNES_EPISODE_TYPE_DESC = "itunes_episode_type_DESC",
  META_FIRSTPUBLICATIONDATE_ASC = "meta_firstPublicationDate_ASC",
  META_FIRSTPUBLICATIONDATE_DESC = "meta_firstPublicationDate_DESC",
  META_LASTPUBLICATIONDATE_ASC = "meta_lastPublicationDate_ASC",
  META_LASTPUBLICATIONDATE_DESC = "meta_lastPublicationDate_DESC",
  SHORT_DESCRIPTION_ASC = "short_description_ASC",
  SHORT_DESCRIPTION_DESC = "short_description_DESC",
  SHOW_NOTES_ASC = "show_notes_ASC",
  SHOW_NOTES_DESC = "show_notes_DESC",
  TITLE_ASC = "title_ASC",
  TITLE_DESC = "title_DESC",
  TRANSCRIPTION_ASC = "transcription_ASC",
  TRANSCRIPTION_DESC = "transcription_DESC",
}

export enum SortPodcasty {
  APPLE_URL_ASC = "apple_url_ASC",
  APPLE_URL_DESC = "apple_url_DESC",
  COPYRIGHT_ASC = "copyright_ASC",
  COPYRIGHT_DESC = "copyright_DESC",
  DEEZER_URL_ASC = "deezer_url_ASC",
  DEEZER_URL_DESC = "deezer_url_DESC",
  DESCRIPTION_ASC = "description_ASC",
  DESCRIPTION_DESC = "description_DESC",
  GOOGLE_PODCASTS_URL_ASC = "google_podcasts_url_ASC",
  GOOGLE_PODCASTS_URL_DESC = "google_podcasts_url_DESC",
  LANGUAGE_ASC = "language_ASC",
  LANGUAGE_DESC = "language_DESC",
  MAX_EPISODES_ASC = "max_episodes_ASC",
  MAX_EPISODES_DESC = "max_episodes_DESC",
  META_FIRSTPUBLICATIONDATE_ASC = "meta_firstPublicationDate_ASC",
  META_FIRSTPUBLICATIONDATE_DESC = "meta_firstPublicationDate_DESC",
  META_LASTPUBLICATIONDATE_ASC = "meta_lastPublicationDate_ASC",
  META_LASTPUBLICATIONDATE_DESC = "meta_lastPublicationDate_DESC",
  NAME_ASC = "name_ASC",
  NAME_DESC = "name_DESC",
  OWNER_EMAIL_ASC = "owner_email_ASC",
  OWNER_EMAIL_DESC = "owner_email_DESC",
  OWNER_NAME_ASC = "owner_name_ASC",
  OWNER_NAME_DESC = "owner_name_DESC",
  POCKET_CASTS_URL_ASC = "pocket_casts_url_ASC",
  POCKET_CASTS_URL_DESC = "pocket_casts_url_DESC",
  SHOW_LINK_ASC = "show_link_ASC",
  SHOW_LINK_DESC = "show_link_DESC",
  SPOTIFY_URL_ASC = "spotify_url_ASC",
  SPOTIFY_URL_DESC = "spotify_url_DESC",
  SUBTITLE_ASC = "subtitle_ASC",
  SUBTITLE_DESC = "subtitle_DESC",
}

export enum SortPrivacyPolicyy {
  META_FIRSTPUBLICATIONDATE_ASC = "meta_firstPublicationDate_ASC",
  META_FIRSTPUBLICATIONDATE_DESC = "meta_firstPublicationDate_DESC",
  META_LASTPUBLICATIONDATE_ASC = "meta_lastPublicationDate_ASC",
  META_LASTPUBLICATIONDATE_DESC = "meta_lastPublicationDate_DESC",
  TITLE_ASC = "title_ASC",
  TITLE_DESC = "title_DESC",
}

export enum SortSiteConfigy {
  META_FIRSTPUBLICATIONDATE_ASC = "meta_firstPublicationDate_ASC",
  META_FIRSTPUBLICATIONDATE_DESC = "meta_firstPublicationDate_DESC",
  META_LASTPUBLICATIONDATE_ASC = "meta_lastPublicationDate_ASC",
  META_LASTPUBLICATIONDATE_DESC = "meta_lastPublicationDate_DESC",
}

export type WhereAboutPage = {
  /** title */
  title_fulltext?: InputMaybe<Scalars["String"]>
}

export type WhereArticle = {
  audio_url?: InputMaybe<Scalars["String"]>
  audio_url_fulltext?: InputMaybe<Scalars["String"]>
  /** author */
  author?: InputMaybe<Scalars["String"]>
  authors?: InputMaybe<WhereArticleAuthors>
  /** banner_image_credits */
  banner_image_credits_fulltext?: InputMaybe<Scalars["String"]>
  categories?: InputMaybe<WhereArticleCategories>
  /** color_scheme */
  color_scheme?: InputMaybe<Scalars["String"]>
  featured?: InputMaybe<Scalars["Boolean"]>
  footnotes?: InputMaybe<WhereArticleFootnotes>
  meta_description?: InputMaybe<Scalars["String"]>
  meta_description_fulltext?: InputMaybe<Scalars["String"]>
  /** subtitle */
  subtitle_fulltext?: InputMaybe<Scalars["String"]>
  /** title */
  title_fulltext?: InputMaybe<Scalars["String"]>
  zotero_footnotes_url?: InputMaybe<Scalars["String"]>
  zotero_footnotes_url_fulltext?: InputMaybe<Scalars["String"]>
}

export type WhereArticleAuthors = {
  /** link */
  link?: InputMaybe<Scalars["String"]>
}

export type WhereArticleCategories = {
  /** category_link */
  category_link?: InputMaybe<Scalars["String"]>
}

export type WhereArticleFootnotes = {
  /** additional_info */
  additional_info_fulltext?: InputMaybe<Scalars["String"]>
  /** link_to_ref */
  link_to_ref?: InputMaybe<Scalars["String"]>
  /** number */
  number?: InputMaybe<Scalars["Float"]>
  /** number */
  number_gt?: InputMaybe<Scalars["Float"]>
  /** number */
  number_lt?: InputMaybe<Scalars["Float"]>
  /** number */
  number_range?: InputMaybe<Array<Scalars["Float"]>>
  ref_title?: InputMaybe<Scalars["String"]>
  ref_title_fulltext?: InputMaybe<Scalars["String"]>
}

export type WhereAuthor = {
  /** bio */
  bio_fulltext?: InputMaybe<Scalars["String"]>
  instagram?: InputMaybe<Scalars["String"]>
  instagram_fulltext?: InputMaybe<Scalars["String"]>
  name?: InputMaybe<Scalars["String"]>
  name_fulltext?: InputMaybe<Scalars["String"]>
  twitter_handle?: InputMaybe<Scalars["String"]>
  twitter_handle_fulltext?: InputMaybe<Scalars["String"]>
}

export type WhereCategory = {
  /** color_scheme */
  color_scheme?: InputMaybe<Scalars["String"]>
  name?: InputMaybe<Scalars["String"]>
  name_fulltext?: InputMaybe<Scalars["String"]>
}

export type WhereColorScheme = {
  name?: InputMaybe<Scalars["String"]>
  name_fulltext?: InputMaybe<Scalars["String"]>
}

export type WhereContentTheme = {
  authors?: InputMaybe<WhereContentThemeAuthors>
  /** heading */
  heading_fulltext?: InputMaybe<Scalars["String"]>
  name?: InputMaybe<Scalars["String"]>
  name_fulltext?: InputMaybe<Scalars["String"]>
}

export type WhereContentThemeAuthors = {
  /** author */
  author?: InputMaybe<Scalars["String"]>
  /** connection */
  connection?: InputMaybe<Scalars["String"]>
}

export type WhereContribute = {
  /** title */
  title_fulltext?: InputMaybe<Scalars["String"]>
}

export type WhereDataPage = {
  /** description */
  description_fulltext?: InputMaybe<Scalars["String"]>
  seo_description?: InputMaybe<Scalars["String"]>
  seo_description_fulltext?: InputMaybe<Scalars["String"]>
  /** title */
  title_fulltext?: InputMaybe<Scalars["String"]>
}

export type WhereNewsletter = {
  description?: InputMaybe<Scalars["String"]>
  description_fulltext?: InputMaybe<Scalars["String"]>
  recommendations?: InputMaybe<WhereNewsletterRecommendations>
  /** series */
  series?: InputMaybe<Scalars["String"]>
  /** title */
  title_fulltext?: InputMaybe<Scalars["String"]>
}

export type WhereNewsletterRecommendations = {
  /** text */
  text_fulltext?: InputMaybe<Scalars["String"]>
}

export type WhereNewsletterSeries = {
  meta_description?: InputMaybe<Scalars["String"]>
  meta_description_fulltext?: InputMaybe<Scalars["String"]>
  meta_title?: InputMaybe<Scalars["String"]>
  meta_title_fulltext?: InputMaybe<Scalars["String"]>
  name?: InputMaybe<Scalars["String"]>
  name_fulltext?: InputMaybe<Scalars["String"]>
  /** page_content */
  page_content_fulltext?: InputMaybe<Scalars["String"]>
}

export type WherePodcast = {
  apple_url?: InputMaybe<Scalars["String"]>
  apple_url_fulltext?: InputMaybe<Scalars["String"]>
  categories?: InputMaybe<WherePodcastCategories>
  /** color_scheme */
  color_scheme?: InputMaybe<Scalars["String"]>
  copyright?: InputMaybe<Scalars["String"]>
  copyright_fulltext?: InputMaybe<Scalars["String"]>
  deezer_url?: InputMaybe<Scalars["String"]>
  deezer_url_fulltext?: InputMaybe<Scalars["String"]>
  description?: InputMaybe<Scalars["String"]>
  description_fulltext?: InputMaybe<Scalars["String"]>
  google_podcasts_url?: InputMaybe<Scalars["String"]>
  google_podcasts_url_fulltext?: InputMaybe<Scalars["String"]>
  language?: InputMaybe<Scalars["String"]>
  language_fulltext?: InputMaybe<Scalars["String"]>
  /** max_episodes */
  max_episodes?: InputMaybe<Scalars["Float"]>
  /** max_episodes */
  max_episodes_gt?: InputMaybe<Scalars["Float"]>
  /** max_episodes */
  max_episodes_lt?: InputMaybe<Scalars["Float"]>
  /** max_episodes */
  max_episodes_range?: InputMaybe<Array<Scalars["Float"]>>
  name?: InputMaybe<Scalars["String"]>
  name_fulltext?: InputMaybe<Scalars["String"]>
  owner_email?: InputMaybe<Scalars["String"]>
  owner_email_fulltext?: InputMaybe<Scalars["String"]>
  owner_name?: InputMaybe<Scalars["String"]>
  owner_name_fulltext?: InputMaybe<Scalars["String"]>
  pocket_casts_url?: InputMaybe<Scalars["String"]>
  pocket_casts_url_fulltext?: InputMaybe<Scalars["String"]>
  show_link?: InputMaybe<Scalars["String"]>
  show_link_fulltext?: InputMaybe<Scalars["String"]>
  spotify_url?: InputMaybe<Scalars["String"]>
  spotify_url_fulltext?: InputMaybe<Scalars["String"]>
  subtitle?: InputMaybe<Scalars["String"]>
  subtitle_fulltext?: InputMaybe<Scalars["String"]>
}

export type WherePodcastCategories = {
  category_name?: InputMaybe<Scalars["String"]>
  category_name_fulltext?: InputMaybe<Scalars["String"]>
}

export type WherePodcastEpisode = {
  audio_file_url?: InputMaybe<Scalars["String"]>
  audio_file_url_fulltext?: InputMaybe<Scalars["String"]>
  categories?: InputMaybe<WherePodcastEpisodeCategories>
  itunes_episode_type?: InputMaybe<Scalars["String"]>
  itunes_episode_type_fulltext?: InputMaybe<Scalars["String"]>
  /** podcast */
  podcast?: InputMaybe<Scalars["String"]>
  short_description?: InputMaybe<Scalars["String"]>
  short_description_fulltext?: InputMaybe<Scalars["String"]>
  /** show_notes */
  show_notes_fulltext?: InputMaybe<Scalars["String"]>
  title?: InputMaybe<Scalars["String"]>
  title_fulltext?: InputMaybe<Scalars["String"]>
  /** transcription */
  transcription_fulltext?: InputMaybe<Scalars["String"]>
}

export type WherePodcastEpisodeCategories = {
  /** category_link */
  category_link?: InputMaybe<Scalars["String"]>
}

export type WherePrivacyPolicy = {
  /** title */
  title_fulltext?: InputMaybe<Scalars["String"]>
}

export type WhereSiteConfig = {
  footer_important_links?: InputMaybe<WhereSiteConfigFooterImportantLinks>
  navbar_links?: InputMaybe<WhereSiteConfigNavbarLinks>
}

export type WhereSiteConfigFooterImportantLinks = {
  is_highlighted?: InputMaybe<Scalars["Boolean"]>
  text?: InputMaybe<Scalars["String"]>
  text_fulltext?: InputMaybe<Scalars["String"]>
  url?: InputMaybe<Scalars["String"]>
  url_fulltext?: InputMaybe<Scalars["String"]>
}

export type WhereSiteConfigNavbarLinks = {
  is_highlighted?: InputMaybe<Scalars["Boolean"]>
  text?: InputMaybe<Scalars["String"]>
  text_fulltext?: InputMaybe<Scalars["String"]>
  url?: InputMaybe<Scalars["String"]>
  url_fulltext?: InputMaybe<Scalars["String"]>
}

/** A prismic document */
export type Document = {
  _meta: Meta
}

/** A connection to a list of items. */
export type DocumentConnection = {
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<DocumentEdge>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  totalCount: Scalars["Long"]
}

/** An edge in a connection. */
export type DocumentEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]
  /** The item at the end of the edge. */
  node: Document
}

/** An external link */
export type ExternalLink = Linkable & {
  _linkType?: Maybe<Scalars["String"]>
  target?: Maybe<Scalars["String"]>
  url: Scalars["String"]
}

/** A linked file */
export type FileLink = Linkable & {
  _linkType?: Maybe<Scalars["String"]>
  name: Scalars["String"]
  size: Scalars["Long"]
  url: Scalars["String"]
}

/** A linked image */
export type ImageLink = Linkable & {
  _linkType?: Maybe<Scalars["String"]>
  height: Scalars["Int"]
  name: Scalars["String"]
  size: Scalars["Long"]
  url: Scalars["String"]
  width: Scalars["Int"]
}

/** A prismic link */
export type Linkable = {
  _linkType?: Maybe<Scalars["String"]>
}

export type Similar = {
  documentId: Scalars["String"]
  max: Scalars["Int"]
}

export type ArticlesForRssQueryVariables = Exact<{ [key: string]: never }>

export type ArticlesForRssQuery = {
  _allDocuments: {
    edges?:
      | Array<
          | {
              node:
                | {
                    __typename: "About_page"
                    _meta: {
                      uid?: string | null | undefined
                      firstPublicationDate?: string | null | undefined
                    }
                  }
                | {
                    __typename: "Article"
                    meta_description?: string | null | undefined
                    subtitle?:
                      | prismic.ImageDetails
                      | prismic.RichTextField
                      | null
                      | undefined
                    banner_image?:
                      | prismic.ImageDetails
                      | prismic.RichTextField
                      | null
                      | undefined
                    banner_image_credits?:
                      | prismic.ImageDetails
                      | prismic.RichTextField
                      | null
                      | undefined
                    rtTitle?:
                      | prismic.ImageDetails
                      | prismic.RichTextField
                      | null
                      | undefined
                    categories?:
                      | Array<{
                          category_link?:
                            | { name?: string | null | undefined }
                            | {}
                            | null
                            | undefined
                        }>
                      | null
                      | undefined
                    _meta: {
                      uid?: string | null | undefined
                      firstPublicationDate?: string | null | undefined
                    }
                  }
                | {
                    __typename: "Author"
                    _meta: {
                      uid?: string | null | undefined
                      firstPublicationDate?: string | null | undefined
                    }
                  }
                | {
                    __typename: "Category"
                    _meta: {
                      uid?: string | null | undefined
                      firstPublicationDate?: string | null | undefined
                    }
                  }
                | {
                    __typename: "Color_scheme"
                    _meta: {
                      uid?: string | null | undefined
                      firstPublicationDate?: string | null | undefined
                    }
                  }
                | {
                    __typename: "Content_theme"
                    _meta: {
                      uid?: string | null | undefined
                      firstPublicationDate?: string | null | undefined
                    }
                  }
                | {
                    __typename: "Contribute"
                    _meta: {
                      uid?: string | null | undefined
                      firstPublicationDate?: string | null | undefined
                    }
                  }
                | {
                    __typename: "Data_page"
                    _meta: {
                      uid?: string | null | undefined
                      firstPublicationDate?: string | null | undefined
                    }
                  }
                | {
                    __typename: "Homepage"
                    _meta: {
                      uid?: string | null | undefined
                      firstPublicationDate?: string | null | undefined
                    }
                  }
                | {
                    __typename: "Newsletter"
                    description?: string | null | undefined
                    preview_image?:
                      | prismic.ImageDetails
                      | prismic.RichTextField
                      | null
                      | undefined
                    rtTitle?:
                      | prismic.ImageDetails
                      | prismic.RichTextField
                      | null
                      | undefined
                    _meta: {
                      uid?: string | null | undefined
                      firstPublicationDate?: string | null | undefined
                    }
                  }
                | {
                    __typename: "Newsletter_series"
                    _meta: {
                      uid?: string | null | undefined
                      firstPublicationDate?: string | null | undefined
                    }
                  }
                | {
                    __typename: "Podcast"
                    _meta: {
                      uid?: string | null | undefined
                      firstPublicationDate?: string | null | undefined
                    }
                  }
                | {
                    __typename: "Podcast_episode"
                    title?: string | null | undefined
                    audio_file_url?: string | null | undefined
                    short_description?: string | null | undefined
                    image?:
                      | prismic.ImageDetails
                      | prismic.RichTextField
                      | null
                      | undefined
                    podcast?:
                      | { _meta: { uid?: string | null | undefined } }
                      | {}
                      | null
                      | undefined
                    _meta: {
                      uid?: string | null | undefined
                      firstPublicationDate?: string | null | undefined
                    }
                  }
                | {
                    __typename: "Privacy_policy"
                    _meta: {
                      uid?: string | null | undefined
                      firstPublicationDate?: string | null | undefined
                    }
                  }
                | {
                    __typename: "Site_config"
                    _meta: {
                      uid?: string | null | undefined
                      firstPublicationDate?: string | null | undefined
                    }
                  }
            }
          | null
          | undefined
        >
      | null
      | undefined
  }
}

export type SiteConfigQueryVariables = Exact<{ [key: string]: never }>

export type SiteConfigQuery = {
  allSite_configs: {
    edges?:
      | Array<
          | {
              node: {
                footer_important_links?:
                  | Array<{
                      text?: string | null | undefined
                      url?: string | null | undefined
                      is_highlighted?: boolean | null | undefined
                    }>
                  | null
                  | undefined
                navbar_links?:
                  | Array<{
                      text?: string | null | undefined
                      url?: string | null | undefined
                      is_highlighted?: boolean | null | undefined
                    }>
                  | null
                  | undefined
              }
            }
          | null
          | undefined
        >
      | null
      | undefined
  }
}

export type AllDocumentTypePathsQueryVariables = Exact<{
  pageSize: Scalars["Int"]
  type: Scalars["String"]
}>

export type AllDocumentTypePathsQuery = {
  docs: {
    edges?:
      | Array<
          | {
              node:
                | { _meta: { uid?: string | null | undefined } }
                | { _meta: { uid?: string | null | undefined } }
                | { _meta: { uid?: string | null | undefined } }
                | { _meta: { uid?: string | null | undefined } }
                | { _meta: { uid?: string | null | undefined } }
                | { _meta: { uid?: string | null | undefined } }
                | { _meta: { uid?: string | null | undefined } }
                | { _meta: { uid?: string | null | undefined } }
                | { _meta: { uid?: string | null | undefined } }
                | { _meta: { uid?: string | null | undefined } }
                | { _meta: { uid?: string | null | undefined } }
                | { _meta: { uid?: string | null | undefined } }
                | { _meta: { uid?: string | null | undefined } }
                | { _meta: { uid?: string | null | undefined } }
                | { _meta: { uid?: string | null | undefined } }
            }
          | null
          | undefined
        >
      | null
      | undefined
  }
}

export type AllEpisodePathsQueryVariables = Exact<{
  pageSize: Scalars["Int"]
}>

export type AllEpisodePathsQuery = {
  docs: {
    edges?:
      | Array<
          | {
              node: {
                _meta: { uid?: string | null | undefined }
                podcast?:
                  | { __typename: "About_page" }
                  | { __typename: "Article" }
                  | { __typename: "Author" }
                  | { __typename: "Category" }
                  | { __typename: "Color_scheme" }
                  | { __typename: "Content_theme" }
                  | { __typename: "Contribute" }
                  | { __typename: "Data_page" }
                  | { __typename: "Homepage" }
                  | { __typename: "Newsletter" }
                  | { __typename: "Newsletter_series" }
                  | {
                      __typename: "Podcast"
                      _meta: { uid?: string | null | undefined; id: string }
                    }
                  | { __typename: "Podcast_episode" }
                  | { __typename: "Privacy_policy" }
                  | { __typename: "Site_config" }
                  | { __typename: "_ExternalLink" }
                  | { __typename: "_FileLink" }
                  | { __typename: "_ImageLink" }
                  | null
                  | undefined
              }
            }
          | null
          | undefined
        >
      | null
      | undefined
  }
}

export type SitemapDataQueryVariables = Exact<{
  endCursor?: InputMaybe<Scalars["String"]>
}>

export type SitemapDataQuery = {
  _allDocuments: {
    totalCount: number
    pageInfo: {
      hasPreviousPage: boolean
      endCursor?: string | null | undefined
      hasNextPage: boolean
    }
    edges?:
      | Array<
          | {
              node:
                | { _meta: { uid?: string | null | undefined; type: string } }
                | { _meta: { uid?: string | null | undefined; type: string } }
                | { _meta: { uid?: string | null | undefined; type: string } }
                | { _meta: { uid?: string | null | undefined; type: string } }
                | { _meta: { uid?: string | null | undefined; type: string } }
                | { _meta: { uid?: string | null | undefined; type: string } }
                | { _meta: { uid?: string | null | undefined; type: string } }
                | { _meta: { uid?: string | null | undefined; type: string } }
                | { _meta: { uid?: string | null | undefined; type: string } }
                | { _meta: { uid?: string | null | undefined; type: string } }
                | { _meta: { uid?: string | null | undefined; type: string } }
                | { _meta: { uid?: string | null | undefined; type: string } }
                | { _meta: { uid?: string | null | undefined; type: string } }
                | { _meta: { uid?: string | null | undefined; type: string } }
                | { _meta: { uid?: string | null | undefined; type: string } }
            }
          | null
          | undefined
        >
      | null
      | undefined
  }
}

export type PlayerEpisodeQueryVariables = Exact<{
  uid: Scalars["String"]
  lang: Scalars["String"]
}>

export type PlayerEpisodeQuery = {
  episode?:
    | {
        title?: string | null | undefined
        audio_file_url?: string | null | undefined
        episode_image?:
          | prismic.ImageDetails
          | prismic.RichTextField
          | null
          | undefined
        short_description?: string | null | undefined
        transcription?:
          | prismic.ImageDetails
          | prismic.RichTextField
          | null
          | undefined
        podcast?:
          | { __typename: "About_page" }
          | { __typename: "Article" }
          | { __typename: "Author" }
          | { __typename: "Category" }
          | { __typename: "Color_scheme" }
          | { __typename: "Content_theme" }
          | { __typename: "Contribute" }
          | { __typename: "Data_page" }
          | { __typename: "Homepage" }
          | { __typename: "Newsletter" }
          | { __typename: "Newsletter_series" }
          | {
              __typename: "Podcast"
              name?: string | null | undefined
              image?:
                | prismic.ImageDetails
                | prismic.RichTextField
                | null
                | undefined
              apple_url?: string | null | undefined
              deezer_url?: string | null | undefined
              google_podcasts_url?: string | null | undefined
              pocket_casts_url?: string | null | undefined
              spotify_url?: string | null | undefined
              _meta: { uid?: string | null | undefined }
            }
          | { __typename: "Podcast_episode" }
          | { __typename: "Privacy_policy" }
          | { __typename: "Site_config" }
          | { __typename: "_ExternalLink" }
          | { __typename: "_FileLink" }
          | { __typename: "_ImageLink" }
          | null
          | undefined
      }
    | null
    | undefined
}

export type ArticlesListQueryVariables = Exact<{
  pageSize: Scalars["Int"]
  after?: InputMaybe<Scalars["String"]>
}>

export type ArticlesListQuery = {
  _allDocuments: {
    edges?:
      | Array<
          | {
              cursor: string
              node:
                | {
                    __typename: "About_page"
                    _meta: {
                      uid?: string | null | undefined
                      firstPublicationDate?: string | null | undefined
                    }
                  }
                | {
                    __typename: "Article"
                    subtitle?:
                      | prismic.ImageDetails
                      | prismic.RichTextField
                      | null
                      | undefined
                    rtTitle?:
                      | prismic.ImageDetails
                      | prismic.RichTextField
                      | null
                      | undefined
                    description?: string | null | undefined
                    image?:
                      | prismic.ImageDetails
                      | prismic.RichTextField
                      | null
                      | undefined
                    categories?:
                      | Array<{
                          category_link?:
                            | { name?: string | null | undefined }
                            | {}
                            | null
                            | undefined
                        }>
                      | null
                      | undefined
                    _meta: {
                      uid?: string | null | undefined
                      firstPublicationDate?: string | null | undefined
                    }
                  }
                | {
                    __typename: "Author"
                    _meta: {
                      uid?: string | null | undefined
                      firstPublicationDate?: string | null | undefined
                    }
                  }
                | {
                    __typename: "Category"
                    _meta: {
                      uid?: string | null | undefined
                      firstPublicationDate?: string | null | undefined
                    }
                  }
                | {
                    __typename: "Color_scheme"
                    _meta: {
                      uid?: string | null | undefined
                      firstPublicationDate?: string | null | undefined
                    }
                  }
                | {
                    __typename: "Content_theme"
                    _meta: {
                      uid?: string | null | undefined
                      firstPublicationDate?: string | null | undefined
                    }
                  }
                | {
                    __typename: "Contribute"
                    _meta: {
                      uid?: string | null | undefined
                      firstPublicationDate?: string | null | undefined
                    }
                  }
                | {
                    __typename: "Data_page"
                    _meta: {
                      uid?: string | null | undefined
                      firstPublicationDate?: string | null | undefined
                    }
                  }
                | {
                    __typename: "Homepage"
                    _meta: {
                      uid?: string | null | undefined
                      firstPublicationDate?: string | null | undefined
                    }
                  }
                | {
                    __typename: "Newsletter"
                    description?: string | null | undefined
                    rtTitle?:
                      | prismic.ImageDetails
                      | prismic.RichTextField
                      | null
                      | undefined
                    image?:
                      | prismic.ImageDetails
                      | prismic.RichTextField
                      | null
                      | undefined
                    _meta: {
                      uid?: string | null | undefined
                      firstPublicationDate?: string | null | undefined
                    }
                  }
                | {
                    __typename: "Newsletter_series"
                    _meta: {
                      uid?: string | null | undefined
                      firstPublicationDate?: string | null | undefined
                    }
                  }
                | {
                    __typename: "Podcast"
                    _meta: {
                      uid?: string | null | undefined
                      firstPublicationDate?: string | null | undefined
                    }
                  }
                | {
                    __typename: "Podcast_episode"
                    title?: string | null | undefined
                    image?:
                      | prismic.ImageDetails
                      | prismic.RichTextField
                      | null
                      | undefined
                    description?: string | null | undefined
                    categories?:
                      | Array<{
                          category_link?:
                            | { name?: string | null | undefined }
                            | {}
                            | null
                            | undefined
                        }>
                      | null
                      | undefined
                    _meta: {
                      uid?: string | null | undefined
                      firstPublicationDate?: string | null | undefined
                    }
                  }
                | {
                    __typename: "Privacy_policy"
                    _meta: {
                      uid?: string | null | undefined
                      firstPublicationDate?: string | null | undefined
                    }
                  }
                | {
                    __typename: "Site_config"
                    _meta: {
                      uid?: string | null | undefined
                      firstPublicationDate?: string | null | undefined
                    }
                  }
            }
          | null
          | undefined
        >
      | null
      | undefined
  }
}

export type ArticlesByAuthorQueryVariables = Exact<{
  authorID: Scalars["String"]
}>

export type ArticlesByAuthorQuery = {
  allArticles: {
    edges?:
      | Array<
          | {
              node: {
                title?:
                  | prismic.ImageDetails
                  | prismic.RichTextField
                  | null
                  | undefined
                subtitle?:
                  | prismic.ImageDetails
                  | prismic.RichTextField
                  | null
                  | undefined
                meta_description?: string | null | undefined
                banner_image?:
                  | prismic.ImageDetails
                  | prismic.RichTextField
                  | null
                  | undefined
                _meta: {
                  uid?: string | null | undefined
                  firstPublicationDate?: string | null | undefined
                }
              }
            }
          | null
          | undefined
        >
      | null
      | undefined
  }
}

export type AuthorBioQueryVariables = Exact<{
  uid: Scalars["String"]
}>

export type AuthorBioQuery = {
  author?:
    | { bio?: prismic.ImageDetails | prismic.RichTextField | null | undefined }
    | null
    | undefined
}

export type HomepageQueryVariables = Exact<{ [key: string]: never }>

export type HomepageQuery = {
  homepage?:
    | {
        body?:
          | Array<
              | {
                  __typename: "HomepageBodyHighlights"
                  fields?:
                    | Array<{
                        highlight?:
                          | { __typename: "About_page" }
                          | {
                              __typename: "Article"
                              title?:
                                | prismic.ImageDetails
                                | prismic.RichTextField
                                | null
                                | undefined
                              meta_description?: string | null | undefined
                              subtitle?:
                                | prismic.ImageDetails
                                | prismic.RichTextField
                                | null
                                | undefined
                              banner_image?:
                                | prismic.ImageDetails
                                | prismic.RichTextField
                                | null
                                | undefined
                              _meta: { uid?: string | null | undefined }
                              categories?:
                                | Array<{
                                    category_link?:
                                      | { __typename: "About_page" }
                                      | { __typename: "Article" }
                                      | { __typename: "Author" }
                                      | {
                                          __typename: "Category"
                                          name?: string | null | undefined
                                          _meta: {
                                            uid?: string | null | undefined
                                          }
                                          color_scheme?:
                                            | { __typename: "About_page" }
                                            | { __typename: "Article" }
                                            | { __typename: "Author" }
                                            | { __typename: "Category" }
                                            | {
                                                __typename: "Color_scheme"
                                                _meta: {
                                                  uid?:
                                                    | string
                                                    | null
                                                    | undefined
                                                }
                                              }
                                            | { __typename: "Content_theme" }
                                            | { __typename: "Contribute" }
                                            | { __typename: "Data_page" }
                                            | { __typename: "Homepage" }
                                            | { __typename: "Newsletter" }
                                            | {
                                                __typename: "Newsletter_series"
                                              }
                                            | { __typename: "Podcast" }
                                            | { __typename: "Podcast_episode" }
                                            | { __typename: "Privacy_policy" }
                                            | { __typename: "Site_config" }
                                            | { __typename: "_ExternalLink" }
                                            | { __typename: "_FileLink" }
                                            | { __typename: "_ImageLink" }
                                            | null
                                            | undefined
                                        }
                                      | { __typename: "Color_scheme" }
                                      | { __typename: "Content_theme" }
                                      | { __typename: "Contribute" }
                                      | { __typename: "Data_page" }
                                      | { __typename: "Homepage" }
                                      | { __typename: "Newsletter" }
                                      | { __typename: "Newsletter_series" }
                                      | { __typename: "Podcast" }
                                      | { __typename: "Podcast_episode" }
                                      | { __typename: "Privacy_policy" }
                                      | { __typename: "Site_config" }
                                      | { __typename: "_ExternalLink" }
                                      | { __typename: "_FileLink" }
                                      | { __typename: "_ImageLink" }
                                      | null
                                      | undefined
                                  }>
                                | null
                                | undefined
                            }
                          | { __typename: "Author" }
                          | { __typename: "Category" }
                          | { __typename: "Color_scheme" }
                          | { __typename: "Content_theme" }
                          | { __typename: "Contribute" }
                          | { __typename: "Data_page" }
                          | { __typename: "Homepage" }
                          | { __typename: "Newsletter" }
                          | { __typename: "Newsletter_series" }
                          | { __typename: "Podcast" }
                          | { __typename: "Podcast_episode" }
                          | { __typename: "Privacy_policy" }
                          | { __typename: "Site_config" }
                          | { __typename: "_ExternalLink" }
                          | { __typename: "_FileLink" }
                          | { __typename: "_ImageLink" }
                          | null
                          | undefined
                      }>
                    | null
                    | undefined
                }
              | { __typename: "HomepageBodyNewest_podcast_episode" }
              | {
                  __typename: "HomepageBodySeries"
                  fields?:
                    | Array<{
                        category?:
                          | { __typename: "About_page" }
                          | { __typename: "Article" }
                          | { __typename: "Author" }
                          | {
                              __typename: "Category"
                              name?: string | null | undefined
                              _meta: {
                                id: string
                                uid?: string | null | undefined
                              }
                              color_scheme?:
                                | { __typename: "About_page" }
                                | { __typename: "Article" }
                                | { __typename: "Author" }
                                | { __typename: "Category" }
                                | {
                                    __typename: "Color_scheme"
                                    _meta: { uid?: string | null | undefined }
                                  }
                                | { __typename: "Content_theme" }
                                | { __typename: "Contribute" }
                                | { __typename: "Data_page" }
                                | { __typename: "Homepage" }
                                | { __typename: "Newsletter" }
                                | { __typename: "Newsletter_series" }
                                | { __typename: "Podcast" }
                                | { __typename: "Podcast_episode" }
                                | { __typename: "Privacy_policy" }
                                | { __typename: "Site_config" }
                                | { __typename: "_ExternalLink" }
                                | { __typename: "_FileLink" }
                                | { __typename: "_ImageLink" }
                                | null
                                | undefined
                            }
                          | { __typename: "Color_scheme" }
                          | { __typename: "Content_theme" }
                          | { __typename: "Contribute" }
                          | { __typename: "Data_page" }
                          | { __typename: "Homepage" }
                          | { __typename: "Newsletter" }
                          | { __typename: "Newsletter_series" }
                          | { __typename: "Podcast" }
                          | { __typename: "Podcast_episode" }
                          | { __typename: "Privacy_policy" }
                          | { __typename: "Site_config" }
                          | { __typename: "_ExternalLink" }
                          | { __typename: "_FileLink" }
                          | { __typename: "_ImageLink" }
                          | null
                          | undefined
                      }>
                    | null
                    | undefined
                }
              | {
                  __typename: "HomepageBodySingle_featured_article"
                  primary?:
                    | {
                        article?:
                          | { __typename: "About_page" }
                          | {
                              __typename: "Article"
                              title?:
                                | prismic.ImageDetails
                                | prismic.RichTextField
                                | null
                                | undefined
                              subtitle?:
                                | prismic.ImageDetails
                                | prismic.RichTextField
                                | null
                                | undefined
                              meta_description?: string | null | undefined
                              banner_image?:
                                | prismic.ImageDetails
                                | prismic.RichTextField
                                | null
                                | undefined
                              _meta: { uid?: string | null | undefined }
                            }
                          | { __typename: "Author" }
                          | { __typename: "Category" }
                          | { __typename: "Color_scheme" }
                          | { __typename: "Content_theme" }
                          | { __typename: "Contribute" }
                          | { __typename: "Data_page" }
                          | { __typename: "Homepage" }
                          | { __typename: "Newsletter" }
                          | { __typename: "Newsletter_series" }
                          | { __typename: "Podcast" }
                          | { __typename: "Podcast_episode" }
                          | { __typename: "Privacy_policy" }
                          | { __typename: "Site_config" }
                          | { __typename: "_ExternalLink" }
                          | { __typename: "_FileLink" }
                          | { __typename: "_ImageLink" }
                          | null
                          | undefined
                      }
                    | null
                    | undefined
                }
            >
          | null
          | undefined
      }
    | null
    | undefined
  podcastEpisodes: {
    edges?:
      | Array<
          | {
              node: {
                title?: string | null | undefined
                image?:
                  | prismic.ImageDetails
                  | prismic.RichTextField
                  | null
                  | undefined
                short_description?: string | null | undefined
                _meta: { uid?: string | null | undefined }
                podcast?:
                  | { __typename: "About_page" }
                  | { __typename: "Article" }
                  | { __typename: "Author" }
                  | { __typename: "Category" }
                  | { __typename: "Color_scheme" }
                  | { __typename: "Content_theme" }
                  | { __typename: "Contribute" }
                  | { __typename: "Data_page" }
                  | { __typename: "Homepage" }
                  | { __typename: "Newsletter" }
                  | { __typename: "Newsletter_series" }
                  | {
                      __typename: "Podcast"
                      image?:
                        | prismic.ImageDetails
                        | prismic.RichTextField
                        | null
                        | undefined
                      _meta: { uid?: string | null | undefined }
                    }
                  | { __typename: "Podcast_episode" }
                  | { __typename: "Privacy_policy" }
                  | { __typename: "Site_config" }
                  | { __typename: "_ExternalLink" }
                  | { __typename: "_FileLink" }
                  | { __typename: "_ImageLink" }
                  | null
                  | undefined
              }
            }
          | null
          | undefined
        >
      | null
      | undefined
  }
}

export type HomepageArticlesByCategoryQueryVariables = Exact<{
  categoryID: Scalars["String"]
}>

export type HomepageArticlesByCategoryQuery = {
  allArticles: {
    edges?:
      | Array<
          | {
              node: {
                title?:
                  | prismic.ImageDetails
                  | prismic.RichTextField
                  | null
                  | undefined
                meta_description?: string | null | undefined
                subtitle?:
                  | prismic.ImageDetails
                  | prismic.RichTextField
                  | null
                  | undefined
                banner_image?:
                  | prismic.ImageDetails
                  | prismic.RichTextField
                  | null
                  | undefined
                _meta: { uid?: string | null | undefined }
              }
            }
          | null
          | undefined
        >
      | null
      | undefined
  }
}

export type NewsletterQueryVariables = Exact<{
  uid: Scalars["String"]
  lang: Scalars["String"]
}>

export type NewsletterQuery = {
  newsletter?:
    | {
        title?: prismic.ImageDetails | prismic.RichTextField | null | undefined
        description?: string | null | undefined
        preview_image?:
          | prismic.ImageDetails
          | prismic.RichTextField
          | null
          | undefined
        recommendations?:
          | Array<{
              image?:
                | prismic.ImageDetails
                | prismic.RichTextField
                | null
                | undefined
              text?:
                | prismic.ImageDetails
                | prismic.RichTextField
                | null
                | undefined
            }>
          | null
          | undefined
      }
    | null
    | undefined
}

export type PodcastEpisodesListQueryVariables = Exact<{
  podcastId: Scalars["String"]
  lang: Scalars["String"]
  pageSize: Scalars["Int"]
  after?: InputMaybe<Scalars["String"]>
}>

export type PodcastEpisodesListQuery = {
  episodes: {
    pageInfo: { hasNextPage: boolean }
    edges?:
      | Array<
          | {
              cursor: string
              node: {
                title?: string | null | undefined
                _meta: { uid?: string | null | undefined }
              }
            }
          | null
          | undefined
        >
      | null
      | undefined
  }
}

export type PodcastPageQueryVariables = Exact<{
  uid: Scalars["String"]
  lang: Scalars["String"]
}>

export type PodcastPageQuery = {
  podcast?:
    | {
        name?: string | null | undefined
        image?: prismic.ImageDetails | prismic.RichTextField | null | undefined
        banner_image?:
          | prismic.ImageDetails
          | prismic.RichTextField
          | null
          | undefined
        description?: string | null | undefined
        apple_url?: string | null | undefined
        deezer_url?: string | null | undefined
        google_podcasts_url?: string | null | undefined
        pocket_casts_url?: string | null | undefined
        spotify_url?: string | null | undefined
        _meta: { id: string; uid?: string | null | undefined }
        color_scheme?:
          | { __typename: "About_page" }
          | { __typename: "Article" }
          | { __typename: "Author" }
          | { __typename: "Category" }
          | {
              __typename: "Color_scheme"
              _meta: { uid?: string | null | undefined }
            }
          | { __typename: "Content_theme" }
          | { __typename: "Contribute" }
          | { __typename: "Data_page" }
          | { __typename: "Homepage" }
          | { __typename: "Newsletter" }
          | { __typename: "Newsletter_series" }
          | { __typename: "Podcast" }
          | { __typename: "Podcast_episode" }
          | { __typename: "Privacy_policy" }
          | { __typename: "Site_config" }
          | { __typename: "_ExternalLink" }
          | { __typename: "_FileLink" }
          | { __typename: "_ImageLink" }
          | null
          | undefined
      }
    | null
    | undefined
}

export type SelectedEpisodeQueryVariables = Exact<{
  uid?: InputMaybe<Scalars["String"]>
  lang: Scalars["String"]
  podcastId: Scalars["String"]
}>

export type SelectedEpisodeQuery = {
  episodes: {
    edges?:
      | Array<
          | {
              node: {
                title?: string | null | undefined
                image?:
                  | prismic.ImageDetails
                  | prismic.RichTextField
                  | null
                  | undefined
                short_description?: string | null | undefined
                show_notes?:
                  | prismic.ImageDetails
                  | prismic.RichTextField
                  | null
                  | undefined
                transcription?:
                  | prismic.ImageDetails
                  | prismic.RichTextField
                  | null
                  | undefined
                audio_file_url?: string | null | undefined
                _meta: {
                  uid?: string | null | undefined
                  firstPublicationDate?: string | null | undefined
                }
              }
            }
          | null
          | undefined
        >
      | null
      | undefined
  }
}

export type NewsletterSeriesQueryVariables = Exact<{
  uid: Scalars["String"]
  lang: Scalars["String"]
}>

export type NewsletterSeriesQuery = {
  newsletter?:
    | {
        meta_title?: string | null | undefined
        meta_description?: string | null | undefined
        image?: prismic.ImageDetails | prismic.RichTextField | null | undefined
        text?: prismic.ImageDetails | prismic.RichTextField | null | undefined
        _meta: { id: string }
      }
    | null
    | undefined
}

export type RecentNewslettersQueryVariables = Exact<{
  id: Scalars["String"]
  lang: Scalars["String"]
}>

export type RecentNewslettersQuery = {
  allNewsletters: {
    edges?:
      | Array<
          | {
              node: {
                title?:
                  | prismic.ImageDetails
                  | prismic.RichTextField
                  | null
                  | undefined
                description?: string | null | undefined
                preview_image?:
                  | prismic.ImageDetails
                  | prismic.RichTextField
                  | null
                  | undefined
                _meta: {
                  uid?: string | null | undefined
                  firstPublicationDate?: string | null | undefined
                }
              }
            }
          | null
          | undefined
        >
      | null
      | undefined
  }
}

export const ArticlesForRssDocument = `
    query ArticlesForRSS {
  _allDocuments(
    type_in: ["article", "podcast_episode", "newsletter"]
    sortBy: meta_firstPublicationDate_DESC
    first: 30
  ) {
    edges {
      node {
        __typename
        _meta {
          uid
          firstPublicationDate
        }
        ... on Article {
          rtTitle: title
          meta_description
          subtitle
          banner_image
          banner_image_credits
          categories {
            ... on ArticleCategories {
              category_link {
                ... on Category {
                  name
                }
              }
            }
          }
        }
        ... on Podcast_episode {
          title
          audio_file_url
          short_description
          image
          podcast {
            ... on Podcast {
              _meta {
                uid
              }
            }
          }
        }
        ... on Newsletter {
          rtTitle: title
          description
          preview_image
        }
      }
    }
  }
}
    `
export const useArticlesForRssQuery = <
  TData = ArticlesForRssQuery,
  TError = unknown,
>(
  variables?: ArticlesForRssQueryVariables,
  options?: UseQueryOptions<ArticlesForRssQuery, TError, TData>,
) =>
  useQuery<ArticlesForRssQuery, TError, TData>(
    variables === undefined
      ? ["ArticlesForRSS"]
      : ["ArticlesForRSS", variables],
    prismicGraphQLClient<ArticlesForRssQuery, ArticlesForRssQueryVariables>(
      ArticlesForRssDocument,
      variables,
    ),
    options,
  )
export const SiteConfigDocument = `
    query SiteConfig {
  allSite_configs {
    edges {
      node {
        footer_important_links {
          text
          url
          is_highlighted
        }
        navbar_links {
          text
          url
          is_highlighted
        }
      }
    }
  }
}
    `
export const useSiteConfigQuery = <TData = SiteConfigQuery, TError = unknown>(
  variables?: SiteConfigQueryVariables,
  options?: UseQueryOptions<SiteConfigQuery, TError, TData>,
) =>
  useQuery<SiteConfigQuery, TError, TData>(
    variables === undefined ? ["SiteConfig"] : ["SiteConfig", variables],
    prismicGraphQLClient<SiteConfigQuery, SiteConfigQueryVariables>(
      SiteConfigDocument,
      variables,
    ),
    options,
  )
export const AllDocumentTypePathsDocument = `
    query AllDocumentTypePaths($pageSize: Int!, $type: String!) {
  docs: _allDocuments(
    first: $pageSize
    sortBy: meta_firstPublicationDate_DESC
    type: $type
  ) {
    edges {
      node {
        _meta {
          uid
        }
      }
    }
  }
}
    `
export const useAllDocumentTypePathsQuery = <
  TData = AllDocumentTypePathsQuery,
  TError = unknown,
>(
  variables: AllDocumentTypePathsQueryVariables,
  options?: UseQueryOptions<AllDocumentTypePathsQuery, TError, TData>,
) =>
  useQuery<AllDocumentTypePathsQuery, TError, TData>(
    ["AllDocumentTypePaths", variables],
    prismicGraphQLClient<
      AllDocumentTypePathsQuery,
      AllDocumentTypePathsQueryVariables
    >(AllDocumentTypePathsDocument, variables),
    options,
  )
export const AllEpisodePathsDocument = `
    query AllEpisodePaths($pageSize: Int!) {
  docs: allPodcast_episodes(
    first: $pageSize
    sortBy: meta_firstPublicationDate_DESC
  ) {
    edges {
      node {
        _meta {
          uid
        }
        podcast {
          __typename
          ... on Podcast {
            _meta {
              uid
              id
            }
          }
        }
      }
    }
  }
}
    `
export const useAllEpisodePathsQuery = <
  TData = AllEpisodePathsQuery,
  TError = unknown,
>(
  variables: AllEpisodePathsQueryVariables,
  options?: UseQueryOptions<AllEpisodePathsQuery, TError, TData>,
) =>
  useQuery<AllEpisodePathsQuery, TError, TData>(
    ["AllEpisodePaths", variables],
    prismicGraphQLClient<AllEpisodePathsQuery, AllEpisodePathsQueryVariables>(
      AllEpisodePathsDocument,
      variables,
    ),
    options,
  )
export const SitemapDataDocument = `
    query SitemapData($endCursor: String) {
  _allDocuments(after: $endCursor) {
    totalCount
    pageInfo {
      hasPreviousPage
      endCursor
      hasNextPage
    }
    edges {
      node {
        _meta {
          uid
          type
        }
      }
    }
  }
}
    `
export const useSitemapDataQuery = <TData = SitemapDataQuery, TError = unknown>(
  variables?: SitemapDataQueryVariables,
  options?: UseQueryOptions<SitemapDataQuery, TError, TData>,
) =>
  useQuery<SitemapDataQuery, TError, TData>(
    variables === undefined ? ["SitemapData"] : ["SitemapData", variables],
    prismicGraphQLClient<SitemapDataQuery, SitemapDataQueryVariables>(
      SitemapDataDocument,
      variables,
    ),
    options,
  )
export const PlayerEpisodeDocument = `
    query PlayerEpisode($uid: String!, $lang: String!) {
  episode: podcast_episode(uid: $uid, lang: $lang) {
    title
    audio_file_url
    episode_image
    short_description
    transcription
    podcast {
      __typename
      ... on Podcast {
        _meta {
          uid
        }
        name
        image
        apple_url
        deezer_url
        google_podcasts_url
        pocket_casts_url
        spotify_url
      }
    }
  }
}
    `
export const usePlayerEpisodeQuery = <
  TData = PlayerEpisodeQuery,
  TError = unknown,
>(
  variables: PlayerEpisodeQueryVariables,
  options?: UseQueryOptions<PlayerEpisodeQuery, TError, TData>,
) =>
  useQuery<PlayerEpisodeQuery, TError, TData>(
    ["PlayerEpisode", variables],
    prismicGraphQLClient<PlayerEpisodeQuery, PlayerEpisodeQueryVariables>(
      PlayerEpisodeDocument,
      variables,
    ),
    options,
  )
export const ArticlesListDocument = `
    query ArticlesList($pageSize: Int!, $after: String) {
  _allDocuments(
    type_in: ["article", "newsletter", "podcast_episode"]
    first: $pageSize
    after: $after
    sortBy: meta_firstPublicationDate_DESC
  ) {
    edges {
      cursor
      node {
        __typename
        _meta {
          uid
          firstPublicationDate
        }
        ... on Article {
          rtTitle: title
          subtitle
          description: meta_description
          image: banner_image
          categories {
            category_link {
              ... on Category {
                name
              }
            }
          }
        }
        ... on Podcast_episode {
          title
          description: short_description
          image
          categories {
            category_link {
              ... on Category {
                name
              }
            }
          }
        }
        ... on Newsletter {
          rtTitle: title
          description
          image: preview_image
        }
      }
    }
  }
}
    `
export const useArticlesListQuery = <
  TData = ArticlesListQuery,
  TError = unknown,
>(
  variables: ArticlesListQueryVariables,
  options?: UseQueryOptions<ArticlesListQuery, TError, TData>,
) =>
  useQuery<ArticlesListQuery, TError, TData>(
    ["ArticlesList", variables],
    prismicGraphQLClient<ArticlesListQuery, ArticlesListQueryVariables>(
      ArticlesListDocument,
      variables,
    ),
    options,
  )
export const ArticlesByAuthorDocument = `
    query ArticlesByAuthor($authorID: String!) {
  allArticles(where: {authors: {link: $authorID}}) {
    edges {
      node {
        _meta {
          uid
          firstPublicationDate
        }
        title
        subtitle
        meta_description
        banner_image
      }
    }
  }
}
    `
export const useArticlesByAuthorQuery = <
  TData = ArticlesByAuthorQuery,
  TError = unknown,
>(
  variables: ArticlesByAuthorQueryVariables,
  options?: UseQueryOptions<ArticlesByAuthorQuery, TError, TData>,
) =>
  useQuery<ArticlesByAuthorQuery, TError, TData>(
    ["ArticlesByAuthor", variables],
    prismicGraphQLClient<ArticlesByAuthorQuery, ArticlesByAuthorQueryVariables>(
      ArticlesByAuthorDocument,
      variables,
    ),
    options,
  )
export const AuthorBioDocument = `
    query AuthorBio($uid: String!) {
  author(uid: $uid, lang: "pt-PT") {
    bio
  }
}
    `
export const useAuthorBioQuery = <TData = AuthorBioQuery, TError = unknown>(
  variables: AuthorBioQueryVariables,
  options?: UseQueryOptions<AuthorBioQuery, TError, TData>,
) =>
  useQuery<AuthorBioQuery, TError, TData>(
    ["AuthorBio", variables],
    prismicGraphQLClient<AuthorBioQuery, AuthorBioQueryVariables>(
      AuthorBioDocument,
      variables,
    ),
    options,
  )
export const HomepageDocument = `
    query Homepage {
  homepage(uid: "homepage", lang: "pt-pt") {
    body {
      __typename
      ... on HomepageBodyHighlights {
        fields {
          highlight {
            __typename
            ... on Article {
              _meta {
                uid
              }
              title
              meta_description
              subtitle
              banner_image
              categories {
                category_link {
                  __typename
                  ... on Category {
                    _meta {
                      uid
                    }
                    name
                    color_scheme {
                      __typename
                      ... on Color_scheme {
                        _meta {
                          uid
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      ... on HomepageBodySeries {
        fields {
          category {
            __typename
            ... on Category {
              _meta {
                id
                uid
              }
              name
              color_scheme {
                __typename
                ... on Color_scheme {
                  _meta {
                    uid
                  }
                }
              }
            }
          }
        }
      }
      ... on HomepageBodySingle_featured_article {
        primary {
          article {
            __typename
            ... on Article {
              _meta {
                uid
              }
              title
              subtitle
              meta_description
              banner_image
            }
          }
        }
      }
    }
  }
  podcastEpisodes: allPodcast_episodes(
    sortBy: meta_firstPublicationDate_DESC
    first: 1
  ) {
    edges {
      node {
        _meta {
          uid
        }
        title
        image
        short_description
        podcast {
          __typename
          ... on Podcast {
            _meta {
              uid
            }
            image
          }
        }
      }
    }
  }
}
    `
export const useHomepageQuery = <TData = HomepageQuery, TError = unknown>(
  variables?: HomepageQueryVariables,
  options?: UseQueryOptions<HomepageQuery, TError, TData>,
) =>
  useQuery<HomepageQuery, TError, TData>(
    variables === undefined ? ["Homepage"] : ["Homepage", variables],
    prismicGraphQLClient<HomepageQuery, HomepageQueryVariables>(
      HomepageDocument,
      variables,
    ),
    options,
  )
export const HomepageArticlesByCategoryDocument = `
    query HomepageArticlesByCategory($categoryID: String!) {
  allArticles(
    first: 4
    where: {categories: {category_link: $categoryID}}
    sortBy: meta_firstPublicationDate_DESC
  ) {
    edges {
      node {
        _meta {
          uid
        }
        title
        meta_description
        subtitle
        banner_image
      }
    }
  }
}
    `
export const useHomepageArticlesByCategoryQuery = <
  TData = HomepageArticlesByCategoryQuery,
  TError = unknown,
>(
  variables: HomepageArticlesByCategoryQueryVariables,
  options?: UseQueryOptions<HomepageArticlesByCategoryQuery, TError, TData>,
) =>
  useQuery<HomepageArticlesByCategoryQuery, TError, TData>(
    ["HomepageArticlesByCategory", variables],
    prismicGraphQLClient<
      HomepageArticlesByCategoryQuery,
      HomepageArticlesByCategoryQueryVariables
    >(HomepageArticlesByCategoryDocument, variables),
    options,
  )
export const NewsletterDocument = `
    query Newsletter($uid: String!, $lang: String!) {
  newsletter(uid: $uid, lang: $lang) {
    title
    description
    preview_image
    recommendations {
      image
      text
    }
  }
}
    `
export const useNewsletterQuery = <TData = NewsletterQuery, TError = unknown>(
  variables: NewsletterQueryVariables,
  options?: UseQueryOptions<NewsletterQuery, TError, TData>,
) =>
  useQuery<NewsletterQuery, TError, TData>(
    ["Newsletter", variables],
    prismicGraphQLClient<NewsletterQuery, NewsletterQueryVariables>(
      NewsletterDocument,
      variables,
    ),
    options,
  )
export const PodcastEpisodesListDocument = `
    query PodcastEpisodesList($podcastId: String!, $lang: String!, $pageSize: Int!, $after: String) {
  episodes: allPodcast_episodes(
    sortBy: meta_firstPublicationDate_DESC
    lang: $lang
    first: $pageSize
    after: $after
    where: {podcast: $podcastId}
  ) {
    pageInfo {
      hasNextPage
    }
    edges {
      cursor
      node {
        _meta {
          uid
        }
        title
      }
    }
  }
}
    `
export const usePodcastEpisodesListQuery = <
  TData = PodcastEpisodesListQuery,
  TError = unknown,
>(
  variables: PodcastEpisodesListQueryVariables,
  options?: UseQueryOptions<PodcastEpisodesListQuery, TError, TData>,
) =>
  useQuery<PodcastEpisodesListQuery, TError, TData>(
    ["PodcastEpisodesList", variables],
    prismicGraphQLClient<
      PodcastEpisodesListQuery,
      PodcastEpisodesListQueryVariables
    >(PodcastEpisodesListDocument, variables),
    options,
  )
export const PodcastPageDocument = `
    query PodcastPage($uid: String!, $lang: String!) {
  podcast(uid: $uid, lang: $lang) {
    _meta {
      id
      uid
    }
    name
    image
    banner_image
    description
    apple_url
    deezer_url
    google_podcasts_url
    pocket_casts_url
    spotify_url
    color_scheme {
      __typename
      ... on Color_scheme {
        _meta {
          uid
        }
      }
    }
  }
}
    `
export const usePodcastPageQuery = <TData = PodcastPageQuery, TError = unknown>(
  variables: PodcastPageQueryVariables,
  options?: UseQueryOptions<PodcastPageQuery, TError, TData>,
) =>
  useQuery<PodcastPageQuery, TError, TData>(
    ["PodcastPage", variables],
    prismicGraphQLClient<PodcastPageQuery, PodcastPageQueryVariables>(
      PodcastPageDocument,
      variables,
    ),
    options,
  )
export const SelectedEpisodeDocument = `
    query SelectedEpisode($uid: String, $lang: String!, $podcastId: String!) {
  episodes: allPodcast_episodes(
    uid: $uid
    lang: $lang
    where: {podcast: $podcastId}
    sortBy: meta_firstPublicationDate_DESC
  ) {
    edges {
      node {
        _meta {
          uid
          firstPublicationDate
        }
        title
        image
        short_description
        show_notes
        transcription
        audio_file_url
      }
    }
  }
}
    `
export const useSelectedEpisodeQuery = <
  TData = SelectedEpisodeQuery,
  TError = unknown,
>(
  variables: SelectedEpisodeQueryVariables,
  options?: UseQueryOptions<SelectedEpisodeQuery, TError, TData>,
) =>
  useQuery<SelectedEpisodeQuery, TError, TData>(
    ["SelectedEpisode", variables],
    prismicGraphQLClient<SelectedEpisodeQuery, SelectedEpisodeQueryVariables>(
      SelectedEpisodeDocument,
      variables,
    ),
    options,
  )
export const NewsletterSeriesDocument = `
    query NewsletterSeries($uid: String!, $lang: String!) {
  newsletter: newsletter_series(uid: $uid, lang: $lang) {
    _meta {
      id
    }
    text: page_content
    meta_title
    meta_description
    image
  }
}
    `
export const useNewsletterSeriesQuery = <
  TData = NewsletterSeriesQuery,
  TError = unknown,
>(
  variables: NewsletterSeriesQueryVariables,
  options?: UseQueryOptions<NewsletterSeriesQuery, TError, TData>,
) =>
  useQuery<NewsletterSeriesQuery, TError, TData>(
    ["NewsletterSeries", variables],
    prismicGraphQLClient<NewsletterSeriesQuery, NewsletterSeriesQueryVariables>(
      NewsletterSeriesDocument,
      variables,
    ),
    options,
  )
export const RecentNewslettersDocument = `
    query RecentNewsletters($id: String!, $lang: String!) {
  allNewsletters(
    where: {series: $id}
    lang: $lang
    first: 3
    sortBy: meta_firstPublicationDate_DESC
  ) {
    edges {
      node {
        _meta {
          uid
          firstPublicationDate
        }
        title
        description
        preview_image
      }
    }
  }
}
    `
export const useRecentNewslettersQuery = <
  TData = RecentNewslettersQuery,
  TError = unknown,
>(
  variables: RecentNewslettersQueryVariables,
  options?: UseQueryOptions<RecentNewslettersQuery, TError, TData>,
) =>
  useQuery<RecentNewslettersQuery, TError, TData>(
    ["RecentNewsletters", variables],
    prismicGraphQLClient<
      RecentNewslettersQuery,
      RecentNewslettersQueryVariables
    >(RecentNewslettersDocument, variables),
    options,
  )
