import type { Podcast } from "~/api/prismic/types"
import { APIRoutes } from "~/enums/APIRoutes"
import { publicConfig } from "~/utils/config"

export function getPodcastRSSFeedURL(
  podcast: Podcast | Pick<Podcast, "uid">,
): string {
  const url = new URL(publicConfig.siteURL)
  const { uid } = podcast

  url.pathname = APIRoutes.PODCAST_RSS_FEED.replace("[uid]", uid)

  return url.toString()
}
