import type { ButtonHTMLAttributes, DetailedHTMLProps } from "react"
import clsx from "clsx"

export type IconButtonProps = DetailedHTMLProps<
  ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>

export const IconButton: React.FC<IconButtonProps> = ({
  className,
  children,
  ...props
}) => {
  return (
    <button
      {...props}
      className={clsx(
        "flex h-9 w-9 items-center justify-center rounded",
        className,
      )}
    >
      {children}
    </button>
  )
}
