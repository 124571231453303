import type { ButtonHTMLAttributes } from "react"
import clsx from "clsx"

type ReactButtonElement = React.FC<ButtonHTMLAttributes<HTMLButtonElement>>

export const SquareButton: ReactButtonElement = ({ className, ...props }) => {
  return (
    <button
      className={clsx(
        "flex h-12 w-12 items-center justify-center p-2",
        "text-primary-500 hover:text-primary-800",
        "dark:text-primary-200 dark:hover:text-primary-100",
        className,
      )}
      type="button"
      {...props}
    />
  )
}
