import type { NextRouter } from "next/router"

import { PageRoutes } from "~/enums/PageRoutes"
import type { FCWithChildren } from "~/types/react"

import { DefaultLayout } from "./Default"
import { NoExtraStyleLayout } from "./NoExtraStyle"

const customLayouts: Record<NextRouter["route"], FCWithChildren> = {
  [PageRoutes.ARTICLE]: NoExtraStyleLayout,
  [PageRoutes.ARTICLES_LIST]: NoExtraStyleLayout,
  [PageRoutes.CATEGORY]: NoExtraStyleLayout,
  [PageRoutes.HOME]: NoExtraStyleLayout,
  [PageRoutes.NEWSLETTER]: NoExtraStyleLayout,
  [PageRoutes.NEWSLETTER_SERIES]: NoExtraStyleLayout,
  [PageRoutes.PODCAST]: NoExtraStyleLayout,
  [PageRoutes.PODCAST_EPISODE]: NoExtraStyleLayout,
}

export const getLayoutComponent = (router: NextRouter): FCWithChildren => {
  return customLayouts[router.route] || DefaultLayout
}
