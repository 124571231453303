import { useState } from "react"
import { Search } from "react-feather"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import { Popover } from "@headlessui/react"

import { useSearchCMSQuery } from "~/api/interruptor/search/query"
import { Input } from "~/components/Input"
import { Loading } from "~/components/Loading"
import { PopoverPanel } from "~/components/PopoverPanel"
import { imageOrFallback } from "~/utils/images/imageOrFallback"

import type { SearchResultProps } from "./SearchResult"
import { SearchResult } from "./SearchResult"

export const SearchMenu = () => {
  const { t } = useTranslation("navbar")

  const [searchText, setSearchText] = useState("")

  const query = useSearchCMSQuery(
    { searchText },
    {
      onError: () => {
        toast.error(t("searchBar.error"))
      },
      select: (data) => {
        return data.map((item): SearchResultProps => {
          return {
            title: item.title,
            href: item.href,
            description: item.description,
            image: {
              src: imageOrFallback(item.image?.url),
              alt: item.image?.alt || "",
            },
          }
        })
      },
    },
  )

  return (
    <Popover className="relative">
      <Popover.Button className="rounded">
        <span className="flex h-9 w-9 items-center justify-center">
          <Search aria-hidden />
          <span className="sr-only">{t("settingsMenu.toggleButton")}</span>
        </span>
      </Popover.Button>
      <PopoverPanel className="fixed inset-x-0 z-10 translate-y-2 sm:absolute sm:left-auto">
        <section className="max-h-[300px] w-[480px] max-w-full space-y-4 overflow-auto text-primary-900 dark:text-primary-50">
          <Input
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            label={t("searchBar.input.label")}
            placeholder={t("searchBar.input.placeholder")}
          />

          {query.isLoading && <Loading className="h-24" size="small" />}

          {!!query.data?.length && (
            <ul className="space-y-4">
              {query.data.map((result) => {
                return (
                  <li key={result.href}>
                    <SearchResult {...result} />
                  </li>
                )
              })}
            </ul>
          )}

          {query.data && !query.data.length && (
            <p className="font-normal italic">{t("searchBar.noResults")}</p>
          )}
        </section>
      </PopoverPanel>
    </Popover>
  )
}
