import type { Icon } from "react-feather"
import { Facebook, GitHub, Instagram, Twitter } from "react-feather"
import { useTranslation } from "react-i18next"

import { publicConfig } from "~/utils/config"

type SocialLink = {
  href: string
  text: string
  Icon: Icon
}
const { links, socialMedia } = publicConfig

export const useSocialLinks = (): SocialLink[] => {
  const { t } = useTranslation("social")

  return [
    {
      href: links.github.url,
      text: t("social:followOn.github"),
      Icon: GitHub,
    },
    {
      href: socialMedia.facebook.url,
      text: t("social:followOn.facebook"),
      Icon: Facebook,
    },
    {
      href: socialMedia.instagram.url,
      text: t("social:followOn.instagram"),
      Icon: Instagram,
    },
    {
      href: socialMedia.twitter.url,
      text: t("social:followOn.twitter"),
      Icon: Twitter,
    },
  ]
}
