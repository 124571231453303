import { createContext, useContext } from "react"

export type SiteConfigLink = {
  text: string
  url: string
  isHighlighted: boolean
}

export type SiteConfig = {
  footer: {
    importantLinks: SiteConfigLink[]
  }
  navbar: {
    links: SiteConfigLink[]
  }
}

export const SiteConfigContext = createContext<SiteConfig | undefined>(
  undefined,
)

SiteConfigContext.displayName = "SiteConfigContext"

export const useSiteConfig = (): SiteConfig => {
  const context = useContext(SiteConfigContext)
  if (!context) {
    throw new Error("[useSiteConfig] The hook was used outside of the provider")
  }
  return context
}
