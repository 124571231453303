import React from "react"
import { Moon, Sun } from "react-feather"
import { useTranslation } from "react-i18next"
import { Switch } from "@headlessui/react"

import { Theme, useThemeCookie } from "~/utils/themes"

export const ThemeSwitch: React.FC = () => {
  const { t } = useTranslation("navbar")

  const [themeCookie, setThemeCookie] = useThemeCookie()
  const theme = themeCookie || Theme.DARK
  const isDarkMode = theme === Theme.DARK

  return (
    <Switch
      checked={isDarkMode}
      onChange={(checked) => {
        setThemeCookie(checked ? Theme.DARK : Theme.LIGHT)
      }}
      className="inline-flex h-8 w-8 items-center justify-center"
    >
      <span className="sr-only">
        {t("settingsMenu.theme.toggle.description")}
      </span>
      {isDarkMode ? <Moon size={20} /> : <Sun size={20} />}
    </Switch>
  )
}
