import type { ImgHTMLAttributes } from "react"

import type { Maybe } from "~/types/ts-utils"
import type { LazyImageOptions } from "~/utils/hooks/useLazyImageRef"
import { useLazyImageRef } from "~/utils/hooks/useLazyImageRef"

type Props = Omit<ImgHTMLAttributes<HTMLImageElement>, "src" | "alt"> &
  LazyImageOptions & {
    src: Maybe<string>
    alt: string
    reallyLazy?: boolean
  }

const LazyImage: React.FC<Props> = ({
  src,
  alt,
  imgixParams,
  lazySize,
  reallyLazy,
  ...rest
}) => {
  const image = useLazyImageRef(src, { lazySize, imgixParams })

  return (
    <img
      {...rest}
      ref={image.ref}
      src={image.url}
      alt={alt}
      loading={reallyLazy ? "lazy" : undefined}
    />
  )
}

export default LazyImage
