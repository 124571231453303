import { useWebMonetization } from "@interruptor/react-monetization"

import { CookieName, useCookie } from "~/utils/cookies"

import { SUPPORTER_COOKIE_VALUE } from "./constants"

export const useIsDonating = (): boolean => {
  const monetization = useWebMonetization()
  const [supporterCookie] = useCookie(CookieName.IS_SUPPORTER)
  const isSupporter = supporterCookie === SUPPORTER_COOKIE_VALUE

  return monetization.isActive || isSupporter
}
