import { useCallback, useState } from "react"
import { useCookies } from "react-cookie"
import type { Cookie as CookieValue, CookieSetOptions } from "universal-cookie"

import { defaultOptions } from "./options"

type UseStrictCookiesResult<TCookieName extends string> = [
  { [K in TCookieName]?: CookieValue },
  (
    name: TCookieName,
    value: CookieValue,
    options?: CookieSetOptions | undefined,
  ) => void,
  (name: TCookieName, options?: CookieSetOptions | undefined) => void,
]

export const useStrictCookies = <TCookieName extends string>(
  dependencies?: TCookieName[],
): UseStrictCookiesResult<TCookieName> => {
  const [cookies, setCookie, removeCookie] =
    useCookies<TCookieName>(dependencies)

  return [
    cookies,
    (name, value, options = {}) => {
      setCookie(name, value, { ...defaultOptions, ...options })
    },
    (name, options = {}) => {
      removeCookie(name, { ...defaultOptions, ...options })
    },
  ]
}

type UseCookieResult<TCookieValue = CookieValue> = [
  TCookieValue | undefined,
  (value: TCookieValue, options?: CookieSetOptions | undefined) => void,
  (options?: CookieSetOptions | undefined) => void,
]

export const useCookie = <TCookieValue>(
  cookieName: string,
): UseCookieResult<TCookieValue> => {
  const [{ [cookieName]: cookieValue }, setCookie, removeCookie] =
    useCookies<string>([cookieName])
  const [stateValue, setStateValue] = useState(cookieValue)

  const setCookieAndState = useCallback(
    (value: TCookieValue, options: CookieSetOptions = {}) => {
      setStateValue(value)
      setCookie(cookieName, value, { ...defaultOptions, ...options })
    },
    [cookieName, setCookie],
  )

  const removeCookieAndState = useCallback(
    (options: CookieSetOptions = {}) => {
      setStateValue(undefined)
      removeCookie(cookieName, { ...defaultOptions, ...options })
    },
    [cookieName, removeCookie],
  )

  return [stateValue, setCookieAndState, removeCookieAndState]
}
