import { useTranslation } from "react-i18next"
import clsx from "clsx"

export type LoadingProps = {
  className?: string
  fullScreen?: boolean
  size?: "small" | "medium"
}

export const Loading: React.FC<LoadingProps> = ({
  className,
  fullScreen,
  size = "medium",
}) => {
  const { t } = useTranslation("general")

  return (
    <div
      className={clsx(
        "flex flex-col items-center justify-center",
        "mx-auto max-w-screen-lg",
        { "min-h-screen-minus-nav": fullScreen },
        className,
      )}
    >
      <img
        className={clsx("animate-pulse", {
          "h-8 sm:h-10 md:h-12": size === "small",
          "h-12 sm:h-16 md:h-20": size === "medium",
        })}
        src="/icon.svg"
        alt={t("loading")}
      />
    </div>
  )
}
