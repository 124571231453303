import React, { Fragment } from "react"
import { Check, ChevronDown } from "react-feather"
import { Listbox, Transition } from "@headlessui/react"
import clsx from "clsx"

export type DropdownProps<TValue> = {
  label: React.ReactNode
  selected: TValue | null
  onChange: (value: TValue) => void
  options: {
    label: React.ReactNode
    value: TValue
  }[]
}

export function Dropdown<TValue>({
  label,
  options,
  selected,
  onChange,
}: DropdownProps<TValue>): JSX.Element {
  const selectedLabel = options.find(
    (option) => option.value === selected,
  )?.label

  return (
    <Listbox value={selected} onChange={onChange}>
      <div
        className={clsx(
          "relative font-display",
          "text-primary-700 theme-peach:text-primary-800 dark:text-primary-200",
        )}
      >
        <div className="flex flex-col">
          <Listbox.Label className="mb-2 text-sm dark:text-secondary-500">
            {label}
          </Listbox.Label>
          <Listbox.Button
            className={clsx(
              "flex items-center justify-between",
              "max-w-md p-2",
              "text-primary-700 dark:text-primary-200",
              "bg-primary-50 dark:bg-primary-600",
              "rounded border border-primary-200 dark:border-primary-400",
            )}
          >
            <span className="block truncate">{selectedLabel}</span>
            <span className="pointer-events-none flex items-center">
              <ChevronDown
                className="h-5 w-5 text-primary-400"
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>
        </div>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options
            className={clsx(
              "absolute z-10 mt-1 max-h-60 w-full overflow-auto py-1",
              "bg-white dark:bg-primary-700",
              "ring-primary-200/5 dark:ring-primary-400/5 rounded ring-1",
              "shadow-lg focus:outline-none",
            )}
          >
            {options.map((option) => (
              <Listbox.Option
                key={JSON.stringify(option.value)}
                className={({ active: isActive, selected: isSelected }) =>
                  clsx(
                    "flex items-center justify-between",
                    "relative cursor-default select-none py-1 px-4",
                    "text-sm",
                    isActive && "bg-primary-100 dark:bg-primary-600",
                    isSelected
                      ? "font-semibold"
                      : "font-normal text-gray-700 dark:text-gray-200",
                  )
                }
                value={option.value}
              >
                {({ selected: isSelected }) => (
                  <>
                    <span className="block truncate">{option.label}</span>
                    {isSelected && (
                      <Check className="h-5 w-5" aria-hidden="true" />
                    )}
                  </>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  )
}
