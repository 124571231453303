import React from "react"
import { X } from "react-feather"
import { useTranslation } from "react-i18next"
import { Slide, ToastContainer } from "react-toastify"

import { useThemeCookie } from "~/utils/themes"

export const ToastProvider: React.FC = () => {
  const { t } = useTranslation("general")
  const [themeCookie] = useThemeCookie()

  const closeText = t("closePopup")

  return (
    <ToastContainer
      limit={3}
      transition={Slide}
      icon={false}
      theme={themeCookie && /dark/.test(themeCookie) ? "dark" : "light"}
      closeButton={({ closeToast }) => (
        <button title={closeText}>
          <X onClick={closeToast} aria-hidden />
          <span className="sr-only">{closeText}</span>
        </button>
      )}
    />
  )
}
