import { Tool } from "react-feather"
import { useTranslation } from "react-i18next"
import { Popover } from "@headlessui/react"

import { PopoverPanel } from "~/components/PopoverPanel"
import { useIsDonating } from "~/utils/donations"

import { FontDropdown } from "./FontDropdown"
import { ThemeDropdown } from "./ThemeDropdown"
import { ThemeSwitch } from "./ThemeSwitch"

export const SettingsMenu = () => {
  const { t } = useTranslation("navbar")
  const isDonating = useIsDonating()

  if (!isDonating) {
    return <ThemeSwitch />
  }

  return (
    <Popover className="relative">
      <Popover.Button className="rounded">
        <span className="flex h-9 w-9 items-center justify-center">
          <Tool aria-hidden />
          <span className="sr-only">{t("settingsMenu.toggleButton")}</span>
        </span>
      </Popover.Button>
      <PopoverPanel className="fixed inset-x-0 z-10 translate-y-2 sm:absolute sm:left-auto">
        <div className="min-w-[240px] space-y-4">
          <FontDropdown />
          <ThemeDropdown />
        </div>
      </PopoverPanel>
    </Popover>
  )
}
