import type { UseQueryResult } from "react-query"

import { ContentLanguage } from "~/api/prismic/enums"
import type { PlayerEpisodeQuery } from "~/api/prismic/graphql"
import { usePlayerEpisodeQuery } from "~/api/prismic/graphql"
import { useCurrentEpisodeUid } from "~/state/Podcast"

export const useCurrentEpisodeQuery =
  (): UseQueryResult<PlayerEpisodeQuery> => {
    const [uid] = useCurrentEpisodeUid()

    return usePlayerEpisodeQuery(
      { uid: uid!, lang: ContentLanguage.PT },
      { enabled: !!uid },
    )
  }
