import { useCallback, useEffect } from "react"

import { useCookie } from "~/utils/cookies"
import { CookieName } from "~/utils/cookies/CookieName"
import { daysToSeconds } from "~/utils/dates/daysToSeconds"

export enum Font {
  DEFAULT = "default",
  BITTER = "bitter",
  INTER = "inter",
  PROZA_LIBRE = "proza-libre",
}

const ALL_FONTS = Object.values(Font)

const ONE_YEAR = daysToSeconds(365)

export const useFontCookie = (): [Font | undefined, (font: Font) => void] => {
  const [fontCookie, setCookie] = useCookie<Font>(CookieName.FONT)

  const setFontCookie = useCallback(
    (value: Font) => setCookie(value, { maxAge: ONE_YEAR }),
    [setCookie],
  )

  // If the user has the cookie, set the font to match it
  useEffect(() => {
    if (!fontCookie) return

    const html = document.querySelector("html")

    const toggleClasses = (font: Font, isActive: boolean) => {
      html?.classList.toggle(`font-${font}`, isActive)
    }

    ALL_FONTS.forEach((font) => {
      const isSelectedFont = fontCookie === font

      toggleClasses(font, isSelectedFont)
    })
  }, [fontCookie])

  return [fontCookie, setFontCookie]
}
