import { APIRoutes } from "~/enums/APIRoutes"
import { publicConfig } from "~/utils/config"

export const getArticlesRSSFeedURL = (): string => {
  const url = new URL(publicConfig.siteURL)

  url.pathname = APIRoutes.ARTICLES_RSS_FEED

  return url.toString()
}
