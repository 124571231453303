export function formatTime(
  timeInSeconds: number,
  alwaysIncludeHours?: boolean,
): string {
  const date = new Date(timeInSeconds * 1000)
  const seconds = date.getUTCSeconds()
  const minutes = date.getUTCMinutes()
  const hours = date.getUTCHours()

  const time = [minutes, seconds]

  if (hours || alwaysIncludeHours) {
    time.unshift(hours)
  }

  return time.map((number) => `${number}`.padStart(2, "0")).join(":")
}
