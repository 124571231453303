import { initReactI18next } from "react-i18next"
import i18n from "i18next"

import articlePage from "./pt/articlePage.json"
import articles from "./pt/articles.json"
import categories from "./pt/categories.json"
import components from "./pt/components.json"
import contentThemePage from "./pt/contentThemePage.json"
import contribute from "./pt/contribute.json"
import data from "./pt/data.json"
import footer from "./pt/footer.json"
import general from "./pt/general.json"
import home from "./pt/home.json"
import monetization from "./pt/monetization.json"
import navbar from "./pt/navbar.json"
import newsletter from "./pt/newsletter.json"
import podcasts from "./pt/podcasts.json"
import privacy from "./pt/privacy.json"
import social from "./pt/social.json"

const resources = {
  pt: {
    articlePage,
    articles,
    categories,
    components,
    contentThemePage,
    contribute,
    data,
    footer,
    general,
    home,
    navbar,
    monetization,
    newsletter,
    podcasts,
    privacy,
    social,
  },
}

void i18n.use(initReactI18next).init({
  lng: process.env.NODE_ENV === "test" ? "cimode" : "pt",
  fallbackLng: "pt",
  defaultNS: "general",
  ns: Object.keys(resources.pt),
  debug:
    process.env.NODE_ENV === "development" && typeof window !== "undefined",
  resources,

  interpolation: {
    escapeValue: false, // not needed for react
  },

  react: {
    useSuspense: false,
  },
})

export default i18n
