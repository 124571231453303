import { useEffect, useState } from "react"
import { Menu, X as Close } from "react-feather"
import { useTranslation } from "react-i18next"
import { animated, useSpring } from "react-spring"
import { useRouter } from "next/router"
import clsx from "clsx"

import LogoYellow from "~/assets/logos/logo-yellow.svg"
import { IconButton } from "~/components/IconButton"
import Link from "~/components/Link"
import { useSiteConfig } from "~/state/SiteConfig"

import { SocialIcons } from "./SocialIcons"

export const MobileMenu: React.FC = () => {
  const { t } = useTranslation("navbar")
  const router = useRouter()
  const [isOpen, setOpen] = useState(false)
  const spring = useSpring({
    transform: `translateX(${isOpen ? 0 : -100}%)`,
  })
  const { navbar } = useSiteConfig()

  useEffect(() => {
    setOpen(false)
  }, [router.asPath])

  useEffect(() => {
    document.body.classList.toggle("max-h-screen", isOpen)
    document.body.classList.toggle("overflow-hidden", isOpen)
  }, [isOpen])

  const toggleNav = () => setOpen(!isOpen)
  const closeNav = () => setOpen(false)

  return (
    <div className="flex items-center md:hidden">
      <IconButton
        title={t("buttons.toggle.ariaLabel")}
        onClick={toggleNav}
        type="button"
      >
        <Menu aria-label={t("buttons.toggle.ariaLabel")} />
      </IconButton>

      <animated.div
        className={clsx(
          "fixed top-0 left-0 z-40",
          "flex flex-col",
          "p-3 md:p-4",
          "h-full w-full",
          "bg-primary-500 theme-peach:bg-gray-800 dark:bg-primary-700",
          "origin-top-right",
        )}
        style={spring}
      >
        <div className="flex items-center justify-between">
          <img {...LogoYellow} alt={t("logo.alt")} className="w-40 pt-1" />
          <IconButton
            className="text-secondary-500"
            title={t("buttons.close.ariaLabel")}
            onClick={closeNav}
            type="button"
          >
            <Close aria-label={t("buttons.close.ariaLabel")} />
          </IconButton>
        </div>

        <ul className="flex flex-1 flex-col items-center justify-center space-y-4">
          {navbar.links.map(({ text, url, isHighlighted }) => (
            <li key={url} className="list-none">
              <Link
                href={url}
                className={clsx(
                  "text-xl font-bold",
                  isHighlighted
                    ? "text-secondary-500 no-underline"
                    : "text-white no-underline",
                )}
                onClick={closeNav}
              >
                {text}
              </Link>
            </li>
          ))}
        </ul>
        <div className="mx-auto mb-4 text-white">
          <SocialIcons />
        </div>
      </animated.div>
    </div>
  )
}
