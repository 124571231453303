import { useEffect, useState } from "react"
import { animated, useSpring } from "react-spring"
import { Router } from "next/router"
import clsx from "clsx"

const LoadingBar: React.FC = () => {
  const [loading, setLoading] = useState(false)
  const springProps = useSpring({ height: loading ? 6 : 0 })

  useEffect(() => {
    const start = () => setLoading(true)
    const stop = () => setLoading(false)

    Router.events.on("routeChangeStart", start)
    Router.events.on("routeChangeComplete", stop)
    Router.events.on("routeChangeError", stop)

    return () => {
      Router.events.off("routeChangeStart", start)
      Router.events.off("routeChangeComplete", stop)
      Router.events.off("routeChangeError", stop)
    }
  }, [])

  return (
    <animated.div
      className="fixed inset-x-0 z-50 bg-white dark:bg-primary-800"
      style={springProps}
    >
      <div
        className={clsx(
          "relative h-full w-full",
          "bg-primary-500 theme-peach:bg-secondary-500 dark:bg-secondary-500",
          { "animate-pulse": loading },
        )}
      />
    </animated.div>
  )
}

export default LoadingBar
