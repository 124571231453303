import React from "react"
import { useTranslation } from "react-i18next"

import { Dropdown } from "~/components/Dropdown"
import { Theme, useThemeCookie } from "~/utils/themes"

export const ThemeDropdown: React.FC = () => {
  const { t } = useTranslation("navbar")
  const [currentTheme, setThemeCookie] = useThemeCookie()

  const options = Object.values(Theme).map((themeName) => ({
    label: t(`settingsMenu.theme.dropdown.options.${themeName}`),
    value: themeName,
  }))

  return (
    <Dropdown<Theme>
      onChange={setThemeCookie}
      options={options}
      selected={currentTheme || null}
      label={t("settingsMenu.theme.dropdown.title")}
    />
  )
}
