import type { Maybe } from "~/types/ts-utils"
import { withoutFalsyValues } from "~/utils/withoutFalsyValues"

type TListQuery = { edges?: Maybe<Array<Maybe<{ node: unknown }>>> }

export type ListQueryNode<T extends TListQuery> = NonNullable<
  NonNullable<T["edges"]>[number]
>["node"]

export function getListQueryNodes<T extends TListQuery>(
  query: Maybe<T>,
): ListQueryNode<T>[] {
  if (!query) return []

  return removeNullEdges(query.edges).map(({ node }) => node)
}

export function removeNullEdges<T>(edges: Maybe<Maybe<T>[]>): T[] {
  const fallback: T[] = []
  const edgesArray = edges || fallback

  return withoutFalsyValues(edgesArray)
}

export type PickByTypename<
  TItem extends { __typename: string },
  TTypename extends string,
> = TItem extends { __typename: TTypename } ? TItem : never

export function pickByTypename<
  TItem extends { __typename: string },
  TTypename extends TItem["__typename"],
>(
  item: Maybe<TItem>,
  typename: TTypename,
): Maybe<PickByTypename<TItem, TTypename>> {
  return filterByTypename([item], typename)[0] || null
}

export function filterByTypename<
  TItem extends { __typename: string },
  TTypename extends TItem["__typename"],
>(
  items: Maybe<Maybe<TItem>[]>,
  typename: TTypename,
): PickByTypename<TItem, TTypename>[] {
  return (items || []).filter(
    (item): item is PickByTypename<TItem, TTypename> =>
      !!item && item.__typename === typename,
  )
}
