/* TODO: Find an accessible way to do this */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

export const ProgressBar: React.FC<{
  currentTime: number
  duration: number
  player: HTMLAudioElement | null
}> = ({ currentTime, duration, player }) => {
  const progressWidth = `${((100 / duration) * currentTime || 0).toFixed(1)}%`

  return (
    <div
      className="relative h-2 cursor-pointer bg-primary-200 dark:bg-primary-600"
      onClick={(e) => {
        if (!player) return
        const position = (1 / window.innerWidth) * e.screenX

        player.currentTime = duration * position
      }}
    >
      <div
        className="absolute top-0 left-0 h-2 bg-primary-500 dark:bg-primary-400"
        style={{ width: progressWidth }}
      />
    </div>
  )
}
