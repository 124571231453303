import type { Maybe, Scalars } from "~/api/prismic/graphql/queries"

export const getImageDetails = (
  item: Maybe<Scalars["Json"]>,
): prismic.ImageDetails | null => {
  return typeof item === "object" && item && "url" in item ? item : null
}

export const getRichTextBlocks = (
  item: Maybe<Scalars["Json"]>,
): prismic.RichTextField => {
  return Array.isArray(item) ? item : []
}
