import { Footer } from "~/components/Footer"
import { NavBar } from "~/components/NavBar"
import { FCWithChildren } from "~/types/react"

export const DefaultLayout: FCWithChildren = ({ children }) => {
  return (
    <>
      <NavBar />
      <main className="container">{children}</main>
      <Footer />
    </>
  )
}
