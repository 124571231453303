export enum PageRoutes {
  ABOUT = "/sobre",
  ARTICLES_LIST = "/artigos",
  ARTICLE = "/artigos/[uid]",
  CATEGORIES_LIST = "/categorias",
  CATEGORY = "/categorias/[uid]",
  CONTENT_THEME = "/series/[uid]",
  CONTRIBUTE = "/contribuir",
  DATA = "/dados",
  HOME = "/",
  NEWSLETTER = "/newsletter/[uid]",
  NEWSLETTER_SERIES = "/newsletter",
  PODCASTS_LIST = "/podcasts",
  PODCAST = "/podcasts/[uid]",
  PODCAST_EPISODE = "/podcasts/[uid]/[episodeUid]",
  PRIVACY_POLICY = "/politica-de-privacidade",
}
