import { Loader, Pause, Play } from "react-feather"
import { useTranslation } from "react-i18next"
import clsx from "clsx"

import { useIsPodcastPlaying } from "~/state/Podcast"

import { useCurrentEpisodeQuery } from "./queries"
import { SquareButton } from "./SquareButton"
import { useIsPodcastBuffering } from "./state"

export const PlayButton: React.FC = () => {
  const { t } = useTranslation("podcasts")

  const [isPlaying, setIsPlaying] = useIsPodcastPlaying()
  const [isBuffering] = useIsPodcastBuffering()

  const { isLoading } = useCurrentEpisodeQuery()

  const isWaitingForData = isBuffering || isLoading

  let message = "buttons.play"
  let Icon = Play

  if (isPlaying) {
    message = "buttons.pause"
    Icon = Pause
  }
  if (isWaitingForData) {
    message = "buttons.buffering"
    Icon = Loader
  }

  return (
    <SquareButton onClick={() => setIsPlaying((x) => !x)}>
      <span className="sr-only">{t(message)}</span>
      <Icon
        className={clsx({ "animate-spin": isWaitingForData })}
        strokeWidth={1.5}
      />
    </SquareButton>
  )
}
