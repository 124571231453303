import { getGraphQLEndpoint } from "@prismicio/client"
import type { GraphQLError } from "graphql"
import type { StringifiableRecord } from "query-string"
import { stringifyUrl } from "query-string"

import { publicConfig } from "~/utils/config"

import type { CreateClientOptions } from "../client"
import { createPrismicClient } from "../client"

type GraphQLResponse<TData> =
  | { data: TData; errors: null }
  | { data: null; errors: GraphQLError[] }

const PRISMIC_GRAPHQL_URL = getGraphQLEndpoint(publicConfig.prismic.repoName)

export const prismicGraphQLClient = <
  TData,
  TVariables extends StringifiableRecord | undefined = undefined,
>(
  query: string,
  variables?: TVariables,
  prismicCLientOptions?: CreateClientOptions,
): (() => Promise<TData>) => {
  return async () => {
    const client = createPrismicClient(prismicCLientOptions)
    const url = stringifyUrl({
      url: PRISMIC_GRAPHQL_URL,
      query: {
        query,
        variables: variables && JSON.stringify(variables),
      },
    })
    const response = await client.graphQLFetch(url)

    if (!response.ok) {
      return Promise.reject(await response.text())
    }

    const json: GraphQLResponse<TData> = await response.json()

    if (json.errors) {
      return Promise.reject(json.errors[0])
    }

    return json.data
  }
}

export const sendPrismicGraphQLRequest = <
  TData,
  TVariables extends StringifiableRecord | undefined = undefined,
>({
  query,
  variables,
  clientOptions,
}: {
  query: string
  variables?: TVariables
  clientOptions?: CreateClientOptions
}): Promise<TData> =>
  prismicGraphQLClient<TData, TVariables>(query, variables, clientOptions)()
