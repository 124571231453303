import React from "react"
import { useTranslation } from "react-i18next"

import { Dropdown } from "~/components/Dropdown"
import { Font, useFontCookie } from "~/utils/fonts"

export const FontDropdown: React.FC = () => {
  const { t } = useTranslation("navbar")
  const [currentFont, setFontCookie] = useFontCookie()

  const options = Object.values(Font).map((fontName) => ({
    label: t(`settingsMenu.font.dropdown.options.${fontName}`),
    value: fontName,
  }))

  return (
    <Dropdown<Font>
      onChange={setFontCookie}
      options={options}
      selected={currentFont || Font.DEFAULT}
      label={t("settingsMenu.font.dropdown.title")}
    />
  )
}
