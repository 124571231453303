import { atom, useAtom } from "jotai"

import type { UseReadWriteAtom } from "~/types/jotai"

export type PodcastState = {
  episodeUid: string | undefined
  isPlaying: boolean
  isBuffering: boolean
}

export const episodeUidAtom = atom<string | undefined>(undefined)
export const useCurrentEpisodeUid: UseReadWriteAtom<string | undefined> = () =>
  useAtom(episodeUidAtom)

export const isPlayingAtom = atom(false)
export const useIsPodcastPlaying: UseReadWriteAtom<boolean> = () =>
  useAtom(isPlayingAtom)
